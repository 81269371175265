import React from 'react';
import { Icon, List, Modal } from 'semantic-ui-react';

const PasswordInstructionsError = ({ open, close, password }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        Invalid Password
      </Modal.Header>
      <Modal.Content>
        <List>
          <List.Header>Password must be at least 8 characters in length and include the following:</List.Header>
          <br />
          <List.Content>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                size={ password && /(.*[A-Z]+.*)$/.test( password ) ? null : 'mini' }
                color={ password &&/(.*[A-Z]+.*)$/.test( password ) ? 'green' : null }
                name={ password &&/(.*[A-Z]+.*)$/.test( password ) ? 'check' : 'circle' }
              />
              &nbsp;
              One uppercase letter
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                size={ password && /(.*[a-z]+.*)$/.test( password ) ? null : 'mini' }
                color={ password && /(.*[a-z]+.*)$/.test( password ) ? 'green' : null }
                name={ password && /(.*[a-z]+.*)$/.test( password ) ? 'check' : 'circle' }
              />
              &nbsp;
              One lowercase letter
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                size={ password && /(.*[0-9]+.*)$/.test( password ) ? null : 'mini' }
                color={ password && /(.*[0-9]+.*)$/.test( password ) ? 'green' : null }
                name={ password && /(.*[0-9]+.*)$/.test( password ) ? 'check' : 'circle' }
              />
              &nbsp;
              One numeric digit
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                size={ password && /(.*[`~!#$%^&*()_=+[\]{}\\|;:’“,<.>/?-]+.*)$/.test( password ) ? null : 'mini' }
                color={ password && /(.*[`~!#$%^&*()_=+[\]{}\\|;:’“,<.>/?-]+.*)$/.test( password ) ? 'green' : null }
                name={ password && /(.*[`~!#$%^&*()_=+[\]{}\\|;:’“,<.>/?-]+.*)$/.test( password ) ? 'check' : 'circle' }
              />
              &nbsp;
              One special character
            </List.Item>
          </List.Content>
        </List>
      </Modal.Content>
    </Modal>
  );
}

export default PasswordInstructionsError;
