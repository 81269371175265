import React from 'react';
import { Input, Button, Header, List, Icon } from 'semantic-ui-react';

export function ReferralConfirm(props) {
  return (
    <React.Fragment>
      <Header style={{ fontSize: '50px' }}>Thank You</Header>
      <Icon name="check" color="blue" size={'massive'} />
      <Header as="h3">
        Thank you for submitting referral code # {props.refCode}
      </Header>
    </React.Fragment>
  );
}
