import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import { Segment, Header, Loader, Dimmer } from 'semantic-ui-react';
import axios from 'axios';
import { getCookie, key, url } from '../../Util';
import _ from 'lodash';

// let LineChart = require('react-chartjs').Line;
let DoughnutChart = require('react-chartjs').Doughnut;
let BarChart = require('react-chartjs').Bar;

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chart1:false,
      chart2:false,
      chart3:false,
    };
  }

  componentDidMount() {

    let date = new Date();
    let mm = date.getMonth();
    let dd = date.getDate();
    let yyyy = date.getFullYear();
    mm = mm + 1;

    let yearPrevious = yyyy - 1;
    let start = `${mm}/${dd}/${yearPrevious}`;
    let today = `${mm}/${dd}/${yyyy}`;
    this.setState({ start, today });

    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }

    if (this.props.profile !== undefined) {
      let site = this.props.profile.site;
      this.setState({ site });
    }
  }

  componentDidUpdate(prevState, prevProps) {
    console.log( this.state);
    if (this.props.profile !== undefined & this.state.site === undefined) {
      let site = this.props.profile.site;
      this.setState({ site });
    }

    if (this.state.today !== undefined && this.state.site !== undefined) {
      if (this.state.chart1 === false)
      this.getGenderChart();

      if (this.state.chart2 === false)
      this.getSubstancesChart();

      if (this.state.chart3 === false)
      this.getReferralChart();
    }
  }

  fetchReportType = (reportType, param, dateStart, dateEnd) => {
    axios({
      method: 'get',
      url:
        url +
        `vizreport/${reportType}?parm=${param}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    })
      .then(res => {
        this.setState({ [reportType]: res.data });

        // console.log("This is dashboard state", this.state);

      })
      .catch(error => console.log(error));
  }

  getGenderChart = () => {
    let start = this.state.start;
    let today = this.state.today;
    let siteId = this.state.site.id;

    this.setState({ chart1: true });

    if (start !== undefined && today !== undefined ) {
      this.fetchReportType(1, siteId, start, today);
    }
  }

  getReferralChart = () => {
    let start = this.state.start;
    let today = this.state.today;
    let siteId = this.state.site.id;

    this.setState({ chart2: true });

    if (start !== undefined && today !== undefined) {
      this.fetchReportType(2, siteId, start, today);
    }
  }

  getSubstancesChart = () => {
    let start = this.state.start;
    let today = this.state.today;
    let siteId = this.state.site.id;

    this.setState({ chart3: true });

    if (start !== undefined && today !== undefined) {
      this.fetchReportType(3, siteId, start, today);
    }
  }

  render() {
    const content = 'SSP Dashboard View';
    const genderPie = <PieChart reportType={1} data={this.state} />;
    const referralBar = (
      <DynamicBarChart
        reportType={2}
        data={this.state}
        width="1080"
        height="550"
        styleName="Chart_1"
      />
    );
    const substanceBar = (
      <DynamicBarChart
        reportType={3}
        data={this.state}
        width="550"
        height="250"
        styleName="Chart_3"
      />
    );

    return (
      <div>
        <TabHeader titleLeft="DASHBOARD" />
        {this.props.render({
          content: content,
          substance: substanceBar,
          gender: genderPie,
          referral: referralBar,
          thing: 'thing',
        })}
      </div>
    );
  }
}

function getRandomColor() {
  //Selecting color based on color range to maintain look/feel coordination.
  var shades = [
    '#f0e6ff',
    '#e0ccff',
    '#d1b3ff',
    '#c299ff',
    '#b380ff',
    '#a366ff',
    '#944dff',
    '#8533ff',
    '#751aff',
    '#6600ff',
    '#5c00e6',
    '#5200cc',
    '#4700b3',
    '#3d0099',
    '#330080',
    '#290066',
    '#1f004d',
    '#140033',
    '#0a001a',
    '#000000',
    '#690df2',
    '#6a13ec',
    '#6b19e6',
    '#6c20df',
    '#6e26d9',
    '#6f2dd2',
    '#7033cc',
    '#7139c6',
    '#7340bf',
    '#7446b9',
    '#754db3',
    '#7753ac',
    '#7859a6',
    '#79609f',
    '#7a6699',
    '#7c6c93',
    '#7d738c',
    '#7e7986',
    '#808080',
  ];
  var used = [];

  var color = '';

  if (shades.length === used.length) {
    //Get random color out of range
    color = getAllRandomColor();
  } else {
    while (!color) {
      var selIndex = Math.floor(Math.random() * shades.length);
      color = shades[selIndex];
    }

    used.push(shades[selIndex]);
    shades[selIndex] = '';
  }

  return color;
}

function getAllRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

// const lineData = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {
//       label: 'My First dataset',
//       fillColor: 'rgba(220,220,220,0.2)',
//       strokeColor: 'rgba(220,220,220,1)',
//       pointColor: 'rgba(220,220,220,1)',
//       pointStrokeColor: '#fff',
//       pointHighlightFill: '#fff',
//       pointHighlightStroke: 'rgba(220,220,220,1)',
//       data: [65, 59, 80, 81, 56, 55, 40],
//     },
//     {
//       label: 'My Second dataset',
//       fillColor: 'rgba(195, 198, 235,0.2)',
//       strokeColor: 'rgba(195, 198, 235,1)',
//       pointColor: 'rgba(195, 198, 235,1)',
//       pointStrokeColor: '#fff',
//       pointHighlightFill: '#fff',
//       pointHighlightStroke: 'rgba(195, 198, 235,1)',
//       data: [28, 48, 40, 19, 86, 27, 90],
//     },
//   ],
// };

// class FirstChart extends React.Component {
//   render() {
//     return (
//       <Segment className="Chart_1">
//         <Header>Monthly Events</Header>
//         <LineChart
//           data={lineData}
//           // options={chartOptions}
//           width="600"
//           height="250"
//           redraw
//         />
//       </Segment>
//     );
//   }
// }

const PieChart = props => {
  let reportType = props.reportType;
  let report = props.data[reportType];
  let data = [];
  let name;
  let description;

  if (report !== undefined) {
    report.results.forEach(result => {
      result.values.forEach(value => {
        let dataSegment = {
          value: value.yVal,
          color: getRandomColor(),
          label: value.xVal,
        };
        data.push(dataSegment);
      });
    });
    name = report.name;
    description = report.description;
  }

  return (
    <Segment className="Chart_2">
      {!data ? (
        <Segment style={{ width: '550px', height: '250px' }} basic>
          <Dimmer active>
            <Loader style={{ marginTop: '10%' }} size="large" />
          </Dimmer>
        </Segment>
      ) : (
        <React.Fragment>
          <Header textAlign="center">{name !== undefined ? name : null}</Header>
          <DoughnutChart data={data} width="500" height="250" />
          <Header sub textAlign="center">
            {description !== undefined ? description : null}
          </Header>
        </React.Fragment>
      )}
    </Segment>
  );
};

const DynamicBarChart = props => {
  let reportType = props.reportType;
  let report = props.data[reportType];
  let barData;
  let name;
  let description;

  if (report !== undefined) {
    let labels = [];
    // let dataSetValues = [];
    let dataSets = [];

    for (let i = 0; i < report.results.length; i++) {
      let dataSetValues = [];

      report.results[i].values.forEach(value => {
        let includes = _.includes(labels, value.xVal);
        if (!includes) {
          labels.push(value.xVal);
        }
      });

      report.results[i].values.forEach(value => {
        dataSetValues.push(value.yVal);
      });

      let newDataSet = {
        label: report.results[i].label,
        fillColor: getRandomColor(),
        data: dataSetValues,
      };

      dataSets.push(newDataSet);
    }

    barData = {
      labels: labels,
      datasets: dataSets,
    };

    name = report.name;
    description = report.description;
  }

  let chartOptions = {
    scaleBeginAtZero: false,
    scaleShowHorizontalLines: true,
    barValueSpacing: 5,
  };

  return (
    <Segment className={props.styleName}>
      {!barData ? (
        <Segment style={{ width: '550px', height: '250px' }} basic>
          <Dimmer active>
            <Loader style={{ marginTop: '10%' }} size="large" />
          </Dimmer>
        </Segment>
      ) : (
        <React.Fragment>
          <Header textAlign="center">{name !== undefined ? name : null}</Header>
          <BarChart
            data={barData}
            width={props.width}
            height={props.height}
            options={chartOptions}
          />
          <Header sub textAlign="center">
            {description !== undefined ? description : null}
          </Header>
        </React.Fragment>
      )}
    </Segment>
  );
};
