import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Input, Button, Header, Label } from 'semantic-ui-react';
import { ReferralConfirm } from './ReferralConfirm';

export function ReferralCapture(props) {

  const [form, setValues] = useState({
    email: '',
    passkey: '',
    refCode: props.location.pathname.split('/')[2],
  });

  const handleChange = e => {
    e.preventDefault();
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.submitReferral(form.email, form.passkey, form.refCode);
  };

  return props.referralSuccess == false || props.referralSuccess == null? (
    <React.Fragment>
      <Header textAlign="left">REFERRAL CODE: {form.refCode == undefined ? "Invalid Referral Code" : form.refCode}</Header>
      <Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder="Enter Username"
        name="email"
        value={form.email}
        onChange={handleChange}
        style={{ margin: '0.5em' }}
      />
      <Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Enter Pin"
        type="password"
        name="passkey"
        value={form.passkey}
        onChange={handleChange}
        style={{ margin: '0.5em' }}
      />

      <div style={{ visibility: form.refCode != undefined && props.referralSuccess == false ? 'visible': 'hidden'}}>
        <Label as='a' basic color='red' pointing>
        Invalid Account/Pin
        </Label>
      </div>
      <Button
        className="btn darkBlue"
        fluid
        size="large"
        style={{
          marginTop: '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '97%',
        }}
        onClick={handleSubmit}
      >
        Complete Referral
      </Button>
    </React.Fragment>
  ) : (
    <ReferralConfirm refCode={form.refCode} />
  );
}
