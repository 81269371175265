import React from 'react';
import { Modal } from 'semantic-ui-react';

const DuplicateProviderLoginModal = ({ open, close }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        Duplicate login Error!
      </Modal.Header>
      <Modal.Content>
        <p>An error occurred and we were unable to create the provider! It appears that a provider with that login already exists. Please try again with a unique login.</p>
      </Modal.Content>
    </Modal>
  );
};

export default DuplicateProviderLoginModal;