import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ReferralCapture } from './ReferralCapture';
import { ReferralConfirm } from './ReferralConfirm';
import { Grid, Form, Segment, Header } from 'semantic-ui-react';

export function ReferralContainer(props) {
  return (
    <div className="referralBackground">
      <div className="referralWrapper">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <Form size="large">
              <Segment className="loginForm" basic>
                <img
                  className="loginLogo"
                  src="../assets/images/logo.png"
                  alt="Indiana State Seal"
                  style={{ width: '150px' }}
                />

                <Header
                  dividing
                  style={{ marginBottom: '5px', fontWeight: '300' }}
                  size="large"
                  textAlign="left"
                >
                  Indiana State Department of Health
                </Header>
                <Header
                  style={{ marginTop: '0px', fontWeight: '300' }}
                  textAlign="left"
                >
                  Referral Confirmation System
                </Header>
                <Segment
                  style={{ marginTop: '0px', marginBottom: '3em' }}
                  padded="very"
                >
                  <ReferralCapture
                    submitReferral={props.submitReferral}
                    location={props.location}
                    referralSuccess={props.referralSuccess}
                  />
                </Segment>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
}
