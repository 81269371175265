import React from 'react';
import { Input, Button, Header, List, Icon } from 'semantic-ui-react';
import { url, key } from '../../Util';
import axios from 'axios';

export class TwoFactor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      newCodeSent: false
    };
  }

  handleChange = (event, { name, value }) => {
    this.setState({ code: value });
  }

  handleSubmit = () => {
    if (this.props.valid === false) {
      const code = this.state.code;
      this.props.twoFactor(code);
    }
  }

  handleNewCode = async () => {
    await axios({
      method: 'get',
      url: `${ url }/user/${ this.props.userID }/resend2auth`,
      headers: {
        api_key: key
      }
    });
    this.setState({ newCodeSent: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header>
          <Icon name="plug" color="grey" size={'huge'} />
          Two Factor Authentication
        </Header>
        <Header sub>
          A message with a verification code has been sent to your email
        </Header>
        <Header sub>Enter the code to continue</Header>
        <Input
          className="authCodeInput"
          style={{ width: '80%', height: '55px' }}
          value={this.state.code}
          onChange={this.handleChange}
        />
        <Header subheader size="small">
          Cancel and go back to <a href="login">Login</a>
        </Header>
        <Button
          color="violet"
          fluid
          size="large"
          style={{
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
          }}
          onClick={this.handleSubmit}
        >
          Authorize Access
        </Button>
        <Button
          color="violet"
          disabled={ this.state.newCodeSent }
          fluid
          size="large"
          style={{
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
          }}
          onClick={ this.handleNewCode }
        >
          { this.state.newCodeSent? 'New Code Sent!' : 'Send New Code' }
        </Button>
      </React.Fragment>
    );
  }
}
