import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  List,
} from 'semantic-ui-react';
import { Main } from '../../Containers/Main';
import { Notifications } from '../Header/Header';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import { EditNotificationsModal } from '../Modals/NotificationModal';

export class NotificationsList extends Component {
  constructor(props) {
    super(props);

    this.state = { showDismissed: false };
  }

  componentDidMount = () => {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
  }

  changeNotificationStatus = notification => {
    let status = notification.notificationStatus;
    if (status === 12) {
      axios({
        method: 'post',
        url: url + 'Notification/User',
        data: {
          ...notification,
          notificationStatus: 13,
        },
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => this.updateNotifications());
    } else {
      if (status === 13) {
        axios({
          method: 'post',
          url: url + 'Notification/User',
          data: {
            ...notification,
            notificationStatus: 12,
          },
          headers: {
            api_key: key,
            AccessToken: getCookie('token')
          },
        }).then(res => this.updateNotifications());
      }
    }
  }

  updateNotifications = () => {
    axios({
      method: 'get',
      url: url + 'Notifications/User',
      headers: {
        api_key: key,
        AccessToken: getCookie('token')
      },
    }).then(res => {
      this.props.listNotifications(res.data);
    });
  }

  toggleNotifications = notificationDisplay => {
    this.setState( prevState => { return { showDismissed: !prevState.showDismissed }; } );
  }

  render() {
    const { visible, notificationsList } = this.props;

    return (
        <React.Fragment>
            { notificationsList !== undefined ? (
              notificationsList.length > 0 ? (
                <React.Fragment>
                  <Segment secondary attached="top" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ color: '#333', fontWeight: 'bold', fontSize: '0.8rem' }}>
                      { `NOTIFICATIONS (${ this.props.notificationCount })` }
                    </span>
                    { this.props.closePopup
                       ? <i
                            className="close icon"
                            style={{ cursor: 'pointer', zIndex: 1, opacity: 0.8, fontSize: '1.25em', width: '2.25rem', color: '#333' }}
                            onClick={ this.props.closePopup }
                          >
                          </i>
                        : null
                    }
                  </Segment>
                  <Segment attached={ Boolean( this.props.viewMore ) ? true : 'bottom' }>
                    { notificationsList.some( item => item.notificationStatus === 13 )
                      ? <Checkbox
                        toggle
                        checked={ this.state.showDismissed }
                        onClick={ this.toggleNotifications }
                        label="Show Dismissed"
                      />
                      : null
                    }
                    <List className="notificationList" divided selection>
                      { notificationsList.map(item => (
                        <List.Item
                          key={item.id}
                          dissmissed={item.notificationStatus}
                          style={
                            item.notificationStatus === 12
                              ? { padding: '1rem 0.5rem' }
                              : { padding: ' 1rem 0.5rem', display: this.state.showDismissed ? 'list-item' : 'none' }
                          }
                        >
                          <List.Content style={{ display: 'flex', minWidth: '300px' }}>
                            <List.Header style={{ flexBasis: '90%', color: item.notificationStatus === 13 ? 'rgba(0,0,0,0.4)' : 'initial' }}>
                              { item.content.split('.', 1) } ...
                            </List.Header>
                            <EditNotificationsModal
                              notification={item}
                              changeNotificationStatus={
                                this.changeNotificationStatus
                              }
                            />
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </Segment>
                  { this.props.viewMore
                    ? <Segment attached="bottom" textAlign="center"><p style={{ color: '#0000EE', cursor: 'pointer' }} onClick={ this.props.viewMore }>view more</p></Segment>
                    : null
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Segment style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 style={{ color: 'black', textAlign: 'center' }}>
                      There are currently no notifications...
                    </h3>
                  </Segment>
                </React.Fragment>
              )
            ) : null }
        </React.Fragment>
    );
  }
}
