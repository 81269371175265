import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import PlaceholderTable from '../Tables/PlaceholderTable';
import { AdminModals } from '../Modals/AdminModals';
import { NavigationBar } from '../Interface/Interface';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import { Loader, Dimmer } from 'semantic-ui-react';
import DuplicateProviderLoginModal from './DuplicateProviderLoginModal';

export class ProviderAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      isLoading: false,
      duplicateProviderLoginModal: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
    this.getProviders();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.providers !== prevState.providers && this.state.providers.length !== prevState.providers.length) {
      this.getProviders();
    }
  }

  getProviders = () => {
    this.props.updateCounter(1, 0);
    axios({
      method: 'get',
      url: url + 'Providers',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      this.props.updateCounter(0, 1);
      this.setState({ providers: res.data, isLoading: false });
    });
  }

  postProvider = async ( provider, genPin = false ) => {
    this.props.updateCounter(1, 0);
    let data = {
      ...provider
    };
    try {
      const res = await axios({
        method: 'post',
        url: url + 'Provider',
        data,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        params: {
          genPin
        }
      });
      if ( res ) {
        this.props.updateCounter(0, 1);
        this.getProviders();
        return true;
      }
    } catch ( e ) {
      this.props.updateCounter(0, 1);
      if ( e.response.status === 409 ) {
        this.setState({ duplicateProviderLoginModal: true });
      }
      return false;
    };
  }

  render() {
    const columns = [
      { Header: 'Provider', accessor: 'name' },
      { Header: 'Contact Name', accessor: 'contactName' },
      { Header: 'Contact Email', accessor: 'contactEmail' },
      { Header: 'Contact Phone', accessor: 'contactPhone' },
      {
        Header: 'Modify Provider',
        accessor: 'id',
        filterable: false,
        Cell: row => (
          <AdminModals
            buttonText="Select Provider"
            color="grey"
            providerRow={row}
            providerID={row.original.id}
            getProviders={this.getProviders}
            providers={this.state.providers}
            handleProviderChange={this.props.handleProviderChange}
            postProvider={this.postProvider}
            updateCounter={this.props.updateCounter}
          />
        ),
      },
    ];
    const data = this.state.providers;
    const Modal = (
      <AdminModals
        buttonText="New Provider"
        color="violet"
        getProviders={this.getProviders}
        providers={this.state.providers}
        handleProviderChange={this.props.handleProviderChange}
        postProvider={this.postProvider}
        updateCounter={this.props.updateCounter}
      />
    );
    const content = (
      <PlaceholderTable
        className="tblProvider"
        style={{ textAlign: 'left', clear: 'right', padding: '5px' }}
        modal={Modal}
        columns={columns}
        data={data}
        recordCount={data.length}
      />
    );

    return (
      <div
        style={{
          background: '#f5f5f5',
          paddingTop: '1em',
          paddingLeft: '10px',
        }}
      >
        <Dimmer active={this.state.isLoading === true}>
          <Loader active={this.state.isLoading === true} />
        </Dimmer>
        <NavigationBar
          setBaseActiveIndex={this.props.setBaseActiveIndex}
          profile={this.props.profile}
        />
        <TabHeader titleLeft="PROVIDER ADMINISTRATION" />
        {this.props.render({ content: content })}
        <DuplicateProviderLoginModal
          open={ this.state.duplicateProviderLoginModal }
          close= { () => this.setState({ duplicateProviderLoginModal: false }) }
        />
      </div>
    );
  }
}
