import React from 'react';
import { LoginForm } from '../Components/Login/LoginForm';
import { TwoFactor } from '../Components/Login/TwoFactor';
import { Main } from './Main';
import {
  Grid,
  Form,
  Segment,
  Header,
  Icon,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { url, key } from '../Util';
import { ReferralCapture } from '../Components/ReferralCapture/ReferralCapture';
import axios from 'axios';
import { Settings } from '../Global/config';
import QaWarningModal from '../Components/Modals/QaWarningModal';

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      valid: false,
      forgotPass: false,
      passwordReset: false,
      changePass: false,
      appVersion: ''
    };
  }

  componentDidMount() {
    const version = localStorage.getItem( 'appVersion' );
    this.setState({ appVersion: JSON.parse( version ) });
  }

  setChangeForgotPass = (option) => {
    // push the user to /reset
    if(option == 'forgot') {
      this.setState({ forgotPass: true, changePass: false });
      this.props.resetError()
    }

    if(option == 'change') {
      this.setState({changePass: true, forgotPass: false});
      this.props.resetError()
    }

    if(option == 'home') {
      this.setState({changePass: false, forgotPass: false, passwordReset: false});
      this.props.resetError()
    }
  }

  resetPass = username => {
    if (username !== undefined) {
      axios({
        method: 'put',
        url: url + 'user/forgotpass?Email=' + username,
        headers: {
          api_key: key,
        },
      }).then(res => {
        if(res.data == true) {
          this.setState({ forgotPass: false, passwordReset: true });
        }

        if(res.data == false) {
          this.props.setError();
         }

      });
    }
  }

  changePassword = (penCode, password) => {
      axios({
        method: 'put',
        url:
          url +
          'user/confirmpass?PenCode=' +
          penCode +
          '&Password=' +
          password,
        headers: {
          api_key: key,
        },
      })
        .then(res => {

         if(res.data == false) {
          this.props.setError();
          this.setState({passChangeSuccess: res.data})
         }

         if(res.data == true) {
          this.setState({
            passChangeSuccess: res.data,
          });
            this.props.resetError();
          }
        }).catch(error => {console.log(error)});
  }

  render() {
    return (
      <div className="loginWrapper">
        <Dimmer active={this.props.isLoading === true}>
          <Loader disabled={this.props.isLoading === false} />
        </Dimmer>
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <Form size="large">
              <Segment className="loginForm" basic>
                <img
                  className="loginLogo"
                  src={ `${ process.env.PUBLIC_URL }/assets/images/logo.png` }
                  alt="Indiana State Seal"
                  style={{ width: '150px' }}
                />
                <Header
                  dividing
                  style={{ marginBottom: '5px', fontWeight: '300' }}
                  size="large"
                  textAlign="left"
                >
                  Indiana State Department of Health
                </Header>
                <Header
                  style={{ marginTop: '0px', fontWeight: '300' }}
                  textAlign="left"
                >
                  Syringe Service Program
                </Header>
                <Segment
                  style={{ marginTop: '3em', marginBottom: '3em' }}
                  padded="very"
                >
                  {this.props.loggedIn === false &&
                  this.props.referral == undefined ? (
                    <LoginForm
                      auth={this.props.auth}
                      loggedIn={this.props.loggedIn}
                      forgotPass={this.state.forgotPass}
                      resetPass={this.resetPass}
                      setChangeForgotPass={this.setChangeForgotPass}
                      passwordReset={this.state.passwordReset}
                      changePass={this.state.changePass}
                      changePassword={this.changePassword}
                      {...this.props}
                      setError={this.props.setError}
                      resetError={this.props.resetError}
                    />
                  ) : this.props.valid === false ? (
                    <TwoFactor
                      twoFactor={this.props.twoFactor}
                      valid={this.props.valid}
                      userID={this.props.userID}
                    />
                  ) : (
                    this.props.history.push('/dashboard')
                  )}
                  <small style={{ position: 'absolute', bottom: '0.5rem', right: '0.5rem', color: 'rgba(0,0,0,0.6)' }}>version { this.state.appVersion }</small>
                </Segment>
                {this.props.error === true ? (
                  <Segment raised>
                    <Header style={{ color: 'red' }}>
                      {this.props.location.pathname !== '/reset'
                        ? ('Error: Invalid Credentials')
                        : ( 'Error: Failed To Update Password')
                      }
                    </Header>
                  </Segment>
                ) : this.state.passwordReset == true && this.props.location.pathname !== '/reset' ? (
                  <Segment raised>
                    <Header style={{ color: 'green' }}>
                      You should recieve an email with instructions to reset
                      your password shortly.
                    </Header>
                  </Segment>
                ) : null}
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
        <QaWarningModal />
      </div>
    );
  }
}
