import React from 'react';
import {
  Form,
  Header,
  Button,
  Icon,
  TextArea,
  Segment,
} from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-auto-format';
import { key, url, getCookie } from '../../Util';
import axios from 'axios';

export class ProviderForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const provider = (
      <Provider
        provider={this.props.provider}
        handleProviderChange={this.props.handleProviderChange}
        handleProviderTypeChange={this.props.handleProviderTypeChange}
        getProviders={this.props.getProviders}
        handleClose={this.props.handleClose}
        providerRow={this.props.providerRow}
        showPinInput={this.props.showPinInput}
      />
    );

    return (
      <React.Fragment>
        {this.props.render({
          provider,
        })}
      </React.Fragment>
    );
  }
}

export class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { confirmMessage: false, phoneNumber: '5551234567', providerTypes: [], pin: '' };
  }
  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
    this.getProviderTypes();
    if ( this.props.provider ) {
      this.setState({ pin: this.props.provider.pin });
    }
  }

  getProviderTypes = () => {
      axios({
        method: 'get',
        url: url + 'Lookups/ReferralTypes',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      })
      .then( res => {
        const providerTypes = res.data.map( type => ({ key: type.id, value: type.id, text: type.description }) );
        console.log( providerTypes );
        this.setState({ providerTypes });
      })
      .catch( err => console.log( err ) );
  }

  genPin = () => {
    let data = {
      ...this.props.provider,
      providerTypes: this.props.provider.providerTypes.map( providerType => ({ id: providerType.id ? providerType.id : providerType }) )
    };
    axios({
      method: 'post',
      url: url + 'Provider',
      data,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
      params: {
        genPin: true
      }
    })
    .then( res => {
      this.setState({ pin: res.data.pin });
    }).catch( err => console.log( err ) );
  }

  deleteProvider = provider => {
    if (this.state.confirmMessage === true) {
      axios({
        method: 'delete',
        url: url + 'Provider/' + provider,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      }).then(res => console.log(res));
      this.props.handleClose();
      this.props.getProviders();
    } else {
      this.setState({ confirmMessage: !this.state.confirmMessage });
    }
  }

  render() {
    const { provider } = this.props;
    return (
      <React.Fragment>
        <Header dividing>
          <Header.Content>
            <Header sub>
              Provider ID: {provider !== undefined ? provider.id : null}
              <br />
              Provider Name: {provider !== undefined ? provider.name : null}
              <br />
              Provider Contact: <br />
              {provider !== undefined ? provider.contactName : null} <br />
              {provider !== undefined ? provider.contactEmail : null}
            </Header>
          </Header.Content>
        </Header>
        <Form.Group style={{ display: 'block' }}>
          <Form.Input
            label="Name"
            name="name"
            onChange={this.props.handleProviderChange}
            value={
              this.props.provider !== undefined
                ? this.props.provider.name
                : null
            }
          />
          <Form.Input
            label="Description"
            name="description"
            onChange={this.props.handleProviderChange}
            value={
              this.props.provider !== undefined
                ? this.props.provider.description
                : null
            }
          />
          <Form.Input
            label="Contact Name"
            name="contactName"
            onChange={this.props.handleProviderChange}
            value={
              this.props.provider !== undefined
                ? this.props.provider.contactName
                : null
            }
          />
          <Form.Input
            label="Contact Email"
            name="contactEmail"
            onChange={this.props.handleProviderChange}
            value={
              this.props.provider !== undefined
                ? this.props.provider.contactEmail
                : null
            }
          />
          <PhoneInput
            label="Contact Phone"
            name="contactPhone"
            inputComponent={Form.Input}
            onChange={ event => {
              this.props.handleProviderChange(event, { name: 'contactPhone', value: event });
            }}
            value={
              this.props.provider !== undefined
                ? this.props.provider.contactPhone
                : this.state.phoneNumber
            }
          />
          <Form.Input
            label="Login"
            name="login"
            onChange={this.props.handleProviderChange}
            value={
              this.props.provider !== undefined
                ? this.props.provider.login
                : null
            }
          />
          { this.props.showPinInput
            ? <Form.Group style={{ display: 'flex', alignItems: 'flex-end', padding: '1rem 0.5rem 0' }}>
              <Form.Input
                id="pin-input"
                width={ 12 }
                label="Pin"
                transparent
                readOnly
                value={ this.state.pin }
              />
              <Form.Button
                content="Reset Pin"
                onClick={ this.genPin }
              />
            </Form.Group>
            : null
          }

          <Form.Select
            options={ this.state.providerTypes }
            multiple
            label="Provider Types"
            name="providerTypes"
            onChange={ this.props.handleProviderTypeChange }
            required={true}
            value={
              this.props.provider ? this.props.provider.providerTypes.map( ({ id }) => id ) : []
            }
          />
          {/* add a multiple dropsdown */}
        </Form.Group>
        {this.props.providerRow ? (
          <Segment
            textAlign="center"
            style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
            basic
          >
            <Header>
              {this.state.confirmMessage === true ? 'Remove Provider?' : null}
            </Header>
            <Button
              color={this.state.confirmMessage === true ? 'red' : 'grey'}
              onClick={() => this.deleteProvider(provider.id)}
            >
              {this.state.confirmMessage === false
                ? 'Delete Provider?'
                : 'Confirm Delete'}
            </Button>
          </Segment>
        ) : null}
      </React.Fragment>
    );
  }
}
