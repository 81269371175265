import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Header, Segment } from 'semantic-ui-react';
import { getCookie, key, url } from '../../Util';
let LineChart = require('react-chartjs').Line;

const SyringesChart = ({ participantID }) => {
	const [ data, setData ] = useState( null );
	const [ lineData, setLineData ] = useState( [] );

	useEffect( () => {
		const start = `01/01/${ new Date().getFullYear() }`;
		const end = `12/31/${ new Date().getFullYear() }`;
		const getReport = async ( parm = participantID, dateStart = start, dateEnd = end ) => {
			try {
				const res = await axios({
					method: 'get',
					baseURL: url,
					url: `vizreport/4`,
					params: {
						parm,
						dateStart,
						dateEnd
					},
					headers: {
						api_key: key,
						AccessToken: getCookie('token'),
					},
				});
	
				setData( res.data );
			} catch ( error ) { console.log(error); };
		};
		getReport();
	}, [] );

	useEffect( () => {
		if ( data ) {
			let labels = data.results[0].values.map( ({ xVal }) => xVal );
			let datasets = [];
			let x = 0;
			for ( let result of data.results ) {


				if (x == 0)
				{
				datasets.push({
					label: result.label,
					fillColor: 'rgba(220,220,220,0.2)',
					strokeColor: 'rgba(61, 30, 114,1)',
					pointColor: 'rgba(61, 30, 114,1)',
					pointStrokeColor: '#fff',
					pointHighlightFill: '#fff',
					pointHighlightStroke: 'rgba(220,220,220,1)',
					data: result.values.map( ({ yVal }) => yVal ),
				});
				}
				else 
				{
					datasets.push({
					label: result.label,
					fillColor: 'rgba(220,220,220,0.2)',
					strokeColor: 'rgba(220,170,0,1)',
					pointColor: 'rgba(220,170,0,1)',
					pointStrokeColor: '#fff',
					pointHighlightFill: '#fff',
					pointHighlightStroke: 'rgba(220,220,220,1)',
					data: result.values.map( ({ yVal }) => yVal ),
					});
				}

				x++;
			}
			setLineData({ labels, datasets });
		}
	}, [ data ]);
	
	return (
		<Segment
			className="Chart_1"
			style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		>
			{ console.log( lineData )}
			<Header>Total Syringe Use</Header>
			<LineChart
				data={ lineData }
				// options={chartOptions}
				width="800"
				height="250"
				redraw
			/>
		</Segment>
	);
};

export default SyringesChart;
