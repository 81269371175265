export const integerKeyDown = e => {
  if (
    e.key !== "Backspace"
    && e.key !== "Tab"
    && e.key !== "0"
    && e.key !== "1"
    && e.key !== "2"
    && e.key !== "3"
    && e.key !== "4"
    && e.key !== "5"
    && e.key !== "6"
    && e.key !== "7"
    && e.key !== "8"
    && e.key !== "9"
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
} // backspace, tab, 0-9, numpad 0-9

export const floatKeyDown = e => {
  if (
    e.key !== "Backspace"
    && e.key !== "Tab"
    && e.key !== "0"
    && e.key !== "1"
    && e.key !== "2"
    && e.key !== "3"
    && e.key !== "4"
    && e.key !== "5"
    && e.key !== "6"
    && e.key !== "7"
    && e.key !== "8"
    && e.key !== "9"
    && e.key !== "."
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
} // backspace, tab, 0-9, numpad 0-9, period, numpad period(s)
