import React from 'react';
import PlaceholderTable from '../Tables/PlaceholderTable';
import { EventsModals } from '../Modals/EventsModals';
import { Button, Loader, Dimmer, Segment, Modal } from 'semantic-ui-react';
import axios from 'axios';
import { key, url, getCookie } from '../../Util';
import Moment from 'react-moment';
import moment from 'moment';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      openConfirmDelete: false,
      idToDelete: '',
      deleteErrorModal: false
    };
  }

  componentDidMount() {
    if (this.props.recordType !== undefined) {
      this.getRecordType(this.props.recordType);
    }

    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.recordType !== this.props.recordType ||
      prevProps.activeComponent !== this.props.activeComponent
    ) {
      this.getRecordType(this.props.recordType);
    }
    if (prevProps.recordType !== this.props.recordType) {
      this.getRecordType(this.props.recordType);
    }

    if(this.state.token == undefined && this.state.token !== prevState.token) {
      let token = getCookie('token');
      this.setState({token});
    }
  }

  getRecordType = recordType => {
    if (recordType == 'Community') {
      this.getRecords(19);
    } else if (recordType == 'Participant') {
      this.getRecords(18);
    } else {
      // this.getRecords();
    }
  }

  deleteRecord = async id => {
    if (this.state.openConfirmDelete == false) {
      this.setState({ openConfirmDelete: true, idToDelete: id });
    }
    if (this.state.openConfirmDelete == true) {
      const res = await this.props.deleteRecord(id);
      if ( res ) {
        this.setState({ openConfirmDelete: false, idToDelete: '' });
        const recordTypeID = this.props.recordType === 'Participant' ? 18 : this.props.recordType === 'Community' ? 19 : null;
        this.getRecords( recordTypeID );
      } else {
        this.setState({ openConfirmDelete: false, idToDelete: '' });
        this.setState({ deleteErrorModal: true });
      }
    }
  }

  handleSearch = (scramblerID, smartID, dateFrom, dateTo) => {
    this.setState({ isLoading: true });
    dateFrom = moment(dateFrom).format('MM/DD/YYYY');
    if(dateTo !== 'Invalid Date') {
      dateTo = moment(dateTo).format('MM/DD/YYYY');
    } else {
      dateTo = moment( JSON.parse(window.localStorage.getItem('DateTo')) ).format('MM/DD/YYYY');
    }

    if(dateFrom !== 'Invalid Date') {
      dateFrom = moment(dateFrom).format('MM/DD/YYYY');
    } else {
      dateFrom = moment( JSON.parse(window.localStorage.getItem('DateFrom')) ).format('MM/DD/YYYY');
    }

    window.localStorage.setItem('DateFrom', JSON.stringify( moment(dateFrom).format('MM/DD/YYYY') ));
    window.localStorage.setItem('DateTo', JSON.stringify( moment(dateTo).format('MM/DD/YYYY') ));
    window.localStorage.setItem('scramblerID', scramblerID);
    window.localStorage.setItem('smartID', smartID);
    let recordType = this.props.recordType == 'Community' ? 19 : 18;
    axios({
      method: 'get',
      url:
        url +
        'Records?DateFrom=' +
        dateFrom +
        '&DateTo=' +
        dateTo +
        '&SmartID=' +
        smartID +
        '&ScramblerID=' +
        scramblerID +
        '&Type=' +
        recordType,
      headers: {
        api_key: key,
        accept: 'application/json',
        AccessToken: getCookie('token'),
      },
    })
      .then(res => {
        let data = res.data;
        let length = res.data.length;

        this.setState({ data, length, isLoading: false });
      })
      .catch(error => {
        let data = [];
        let length = 0;
        this.setState({ data, length, isLoading: false });
      });
  }

  getRecords = eventType => {
    let storedDateFrom = JSON.parse( window.localStorage.getItem('DateFrom') );
    let storedDateTo = JSON.parse( window.localStorage.getItem('DateTo') );

    let storedScramblerID = window.localStorage.getItem('scramblerID');
    let storedSmartID = window.localStorage.getItem('smartID');
    let token = getCookie('token');

    if (storedSmartID !== null || storedScramblerID !== null) {
      this.handleSearch(
        storedScramblerID,
        storedSmartID,
        storedDateFrom,
        storedDateTo
      );
    } else if (storedDateFrom !== null) {
      this.setState({ isLoading: true });
      let today = moment(storedDateTo).format('MM/DD/YYYY');
      let end = moment(storedDateFrom).format('MM/DD/YYYY');

      axios({
        method: 'get',
        url:
          url +
          'Records?DateFrom=' +
          end +
          '&DateTo=' +
          today +
          '&SmartID=' +
          '' +
          '&ScramblerID=' +
          '' +
          '&Type=' +
          eventType,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      })
        .then(res => {
          let data = res.data;
          let length = res.data.length;
          this.setState({ data, length, isLoading: false });
        })
        .catch(error => {
          let data = [];
          let length = 0;
          this.setState({ data, length, isLoading: false });
        });
    } else {
      this.setState({ isLoading: true });
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth();
      let yyyy = today.getFullYear();

      if (dd < 10 && dd.length <= 2) {
        dd = '0' + dd;
      }
      if (mm < 10 && mm.length <= 2) {
        mm = '0' + mm;
      }

      if (dd < 29) {
        today =
          (mm + 1).toString() +
          '/' +
          (dd + 2).toString() +
          '/' +
          yyyy.toString();
      } else {
        today =
          (mm + 2).toString() + '/' + dd.toString() + '/' + yyyy.toString();
      }
      let end;
      if (mm <= 1) {
        end = '12' + '/' + dd.toString() + '/' + (yyyy - 1).toString();
      } else {
        end = (mm - 1).toString() + '/' + dd.toString() + '/' + yyyy.toString();
      }

      axios({
        method: 'get',
        url:
          url +
          'Records?DateFrom=' +
          end +
          '&DateTo=' +
          today +
          '&SmartID=' +
          '' +
          '&ScramblerID=' +
          '' +
          '&Type=' +
          eventType,
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
      })
        .then(res => {
          let data = res.data;
          let length = res.data.length;
          window.localStorage.setItem('DateFrom', JSON.stringify( moment(end).format('MM/DD/YYYY') ));
          window.localStorage.setItem('DateTo', JSON.stringify( moment(today).format('MM/DD/YYYY') ));
          window.localStorage.setItem('eventType', eventType.toString());
          this.setState({ data, length, isLoading: false });
        })
        .catch(error => {
          let data = [];
          let length = 0;
          this.setState({ data, length, isLoading: false });
        });
    }
  }

  render() {
    let columns = [];
    let { data, length } = this.state;
      if (data) {
        data.map(col => {
          let arr = Object.keys(col);
          columns = arr.map(a => ({ Header: a, accessor: a }));

          let button = {
            Header: 'Select',
            accessor: 'id',
            filterable: false,
            Cell: row => (
              <Button.Group>{this.state.idToDelete !== row.original.id ? (
                <Button
                  row={row}
                  onClick={ async () => {
                    await this.props.getRecords( row.original.id );
                    this.props.setActiveComponent('baseRecord', row.original);
                  }}
                >
                  Select Participant
                </Button>
              ) : (
                <Button
                row={row}
                onClick={() => this.setState({ openConfirmDelete: false, idToDelete: '' })}
              >
                Cancel
              </Button>
              )}
                { this.props.profile.role.id === 1 || this.props.profile.role.id === 2 ? (
                    <Button
                      row={row}
                      onClick={ () => {
                        this.deleteRecord(row.original.id);
                      }}
                      style={
                          row.original.id == this.state.idToDelete ? {backgroundColor: 'green', color: 'white'} : {backgroundColor: 'red', color: 'white'}
                      }
                      content={
                        this.state.openConfirmDelete == true ? 'Confirm' : 'Delete'
                      }
                    />
                  ) : null
                }
              </Button.Group>
            ),
          };

          let filterVal;

          let dateRecorded = {
            Header: 'Date Recorded',
            accessor: 'dateRecorded',
            filterable: false,
            Cell: row => (
              <Moment format="MM/DD/YYYY">{row.original.dateRecorded}</Moment>
            ),
          };

          let visitCount = {
            Header: 'Number of Events',
            accessor: 'recordCount',
            filterable: false
          };
          let mostRecentVisit = {
            Header: 'Most Recent Visit',
            accessor: 'recentRecordDate',
            filterable: false,
            Cell: row => (
              moment( row.original.recentRecordDate ).format('L') === '01/01/1900'
              ? '--'
              : <Moment format="MM/DD/YYYY">{row.original.recentRecordDate}</Moment>
            ),
          };
          let firstVisit = {
            Header: 'First Visit',
            accessor: 'firstRecordDate',
            filterable: false,
            Cell: row => (
              moment( row.original.firstRecordDate ).format('L') === '01/01/1900'
              ? '--'
              : <Moment format="MM/DD/YYYY">{row.original.firstRecordDate}</Moment>
            ),
          };
          let historyID = {
            Header: 'History ID',
            accessor: 'historyID',
            Cell: row => row.original.historyID ? <span>{ row.original.historyID }</span> : <span>{ "" }</span>
          };
          let smartID = {
            Header: this.props.recordType === 'Participant' ? 'Smart ID' : this.props.recordType === 'Community' ? 'Community ID' : null,
            accessor: 'smartID',
          };
          let scramblerID = {
            Header: 'Scrambler ID',
            accessor: 'scramblerID',
          };

          columns = columns.filter(column => column.Header !== 'inputData');
          columns = columns.filter(column => column.Header !== 'records');
          columns = columns.filter(column => column.Header !== 'notes');
          columns = columns.filter(column => column.Header !== 'id');
          columns = columns.filter(column => column.Header !== 'status');
          columns = columns.filter(column => column.Header !== 'dateRecorded');
          columns = columns.filter(column => column.Header !== 'historyID');
          columns = columns.filter(column => column.Header !== 'smartID');
          columns = columns.filter(column => column.Header !== 'scramblerID');
          columns = columns.filter(column => column.Header !== 'type');
          columns = columns.filter(column => column.Header !== 'firstRecordDate');
          columns = columns.filter(column => column.Header !== 'recentRecordDate');
          columns = columns.filter(column => column.Header !== 'recordCount');
          columns.push(scramblerID, smartID, historyID, firstVisit, mostRecentVisit, visitCount, button);
        })
      }
    

    const ModalElement = (
      <BaseObjectConsumer>
        {({ postNewParticipant, isLoading }) => (
          <EventsModals
            buttonText={
              this.props.recordType !== undefined
                ? `New ${this.props.recordType}`
                : null
            }
            color="purple"
            setActiveComponent={this.props.setActiveComponent}
            updateSmartID={this.props.updateSmartID}
            postNewParticipant={postNewParticipant}
          />
        )}
      </BaseObjectConsumer>
    );

    const search = (
      <EventsModals
        buttonText="Search"
        updateSmartID={this.props.updateSmartID}
        handleSearch={this.handleSearch}
        recordType={this.props.recordType}
      />
    );
    return (
      <React.Fragment>
        <BaseObjectConsumer>
          { ({ isLoading }) => (
            this.state.isLoading  == true ? (
              <Segment style={{ height: '300px' }}>
                <Dimmer active size="massive">
                  <Loader style={{ marginTop: '10px' }} />
                </Dimmer>
              </Segment>
            ) : (
              <React.Fragment>
                <PlaceholderTable
                  modal={ModalElement}
                  className="tblBase"
                  style={{ textAlign: 'center', clear: 'right', padding: '5px' }}
                  columns={columns}
                  data={data}
                  search={search}
                  recordCount={length}
                  updateSmartID={this.props.updateSmartID}
                  dateRange={ `${window.localStorage.getItem('DateFrom') || ''} - ${window.localStorage.getItem('DateTo') || ''}` }
                  scramblerID={ window.localStorage.getItem('scramblerID') }
                  smartID={ window.localStorage.getItem('smartID') }
                />
                <Modal
                  open={ this.state.deleteErrorModal }
                  onClose={ () => this.setState({ deleteErrorModal: false }) }
                  size="mini"
                >
                  <Modal.Content style={{ textAlign: 'center' }}>
                    Unable to delete this record.
                    <br />
                    Please make sure to remove all events associated with this record before deleting.
                  </Modal.Content>
                </Modal>
              </React.Fragment>
            )
          )}
        </BaseObjectConsumer>
      </React.Fragment>
    );
  }
}

export default Base;
