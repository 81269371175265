import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { key, url, getCookie } from '../../Util';

const BiReporting = () => {
	const [ iframeUrl, setIframeUrl ] = useState( '' );

	useEffect( () => {
		( async () => {
			const AccessToken = getCookie( 'token' );

			try {
				const { data } = await axios( {
					baseURL: url,
					url: '/user/authenticate/adhoc',
					headers: {
						api_key: key,
						AccessToken
					}
				} );
	
				setIframeUrl( `https://isdhbi.kineticiq.com?t=${ data.token }` );
	
			} catch ( err ) {
				console.error( err );
			}
		} )();
	} , [] );

	return (
		<React.Fragment>
			{ iframeUrl
					? <div>
						<iframe src={ iframeUrl } title="bi-reporting" style={ { height: '943px', width: '100%', border: 'none' } }></iframe>
					</div>
					:	<div style={ { height: '943px', width: '100%' } }>
						loading...
					</div>
			}
		</React.Fragment>
	);
};

export default BiReporting;