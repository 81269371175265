
export const url = 'https://sspapi.azurewebsites.net/';
// export const url = 'http://localhost:53966/';
export const key = 'SVNESFNTUEFkbWluOlpRdTM2U2V1Zlo4SGFKRw==';

export function formatDate(date) {
  if (date !== undefined) {
    let split = date.split('T', 1).toString();
    let resplit = split.split('-');
    let flip = resplit.reverse();
    let newDate = flip.join('/');
    return newDate;
  }
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function checkCookie(name) {
  var name = getCookie(name);
  if (name != '') {
    return name;
  } else {
    console.log('unauthorized');
  }
}

export const validateEmail = value => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
};

export const validatePhone = value => {
  if ( value ) {
    return /^[0-9]{10}$/.test( value.replace( /[^0-9]/g, '' ) );
  }

  return false;
};