import React from 'react';
import {
  Modal
} from 'semantic-ui-react';
import { NotificationsList } from '../Sidebar/NotificationsList';

const NotificationsListModal = ({ open, handleModal, notificationsList, listNotifications, handleButtonClick }) => {
	return (
		<Modal
        open={open}
        onClose={handleModal}
        size="large"
        closeIcon
      >
        <Modal.Content>
				<NotificationsList
					notificationsList={notificationsList}
					listNotifications={listNotifications}
					handleButtonClick={handleButtonClick}
				/>
        </Modal.Content>
      </Modal>
	);
};

export default NotificationsListModal;