import React from 'react';
import { Icon, List, Modal } from 'semantic-ui-react';
import { validateEmail, validatePhone } from '../../Util';

const RequiredFieldsError = ({ open, close, user, checkPass }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        One or more required fields are missing
      </Modal.Header>
      <Modal.Content>
        <List bulleted>
          <List.Header>
            Please confirm that you have entered the following fields:
          </List.Header>
          <br />
          <List.Content>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ user.firstName ? 'green' : 'red' }
                name={ user.firstName ? 'check' : 'times' }
              />
              &nbsp;
              First Name
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ user.lastName ? 'green' : 'red' }
                name={ user.lastName ? 'check' : 'times' }
              />
              &nbsp;
              Last Name
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ user.userName ? 'green' : 'red' }
                name={ user.userName ? 'check' : 'times' }
              />
              &nbsp;
              User Name
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ validateEmail( user.email ) ? 'green' : 'red' }
                name={ validateEmail( user.email ) ? 'check' : 'times' }
              />
              &nbsp;
              Email
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ validatePhone( user.phone ) ? 'green' : 'red' }
                name={ validatePhone( user.phone ) ? 'check' : 'times' }
              />
              &nbsp;
              Phone
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ user.role ? 'green' : 'red' }
                name={ user.role ? 'check' : 'times' }
              />
              &nbsp;
              Role
            </List.Item>
            { checkPass
              ? <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                <List.Icon
                  color={ user.password ? 'green' : 'red' }
                  name={ user.password ? 'check' : 'times' }
                />
                &nbsp;
                Password
              </List.Item>
              : null
            }
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ user.site ? 'green' : 'red' }
                name={ user.site ? 'check' : 'times' }
              />
              &nbsp;
              Site
            </List.Item>
          </List.Content>
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default RequiredFieldsError;
