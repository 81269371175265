import React from 'react';
import _ from 'lodash';
import {
  Header,
  Title,
  Profile,
  Notifications,
  Settings,
} from '../Components/Header/Header';
import axios from 'axios';
import { Sidebar } from '../Components/Sidebar/Sidebar';
import {
  Menu,
  Tab,
  Card,
  Button,
  Responsive,
  Loader,
  Dimmer,
  Icon,
} from 'semantic-ui-react';
import { SidebarPanes } from '../Components/Sidebar/SidebarPanes';
import { Interface } from '../Components/Interface/Interface';
import { url, key, setCookie } from '../Util';
import { UserAdmin } from '../Components/Admin/UserAdmin';
import { SiteAdmin } from '../Components/Admin/SiteAdmin';
import NotificationAdmin from '../Components/Admin/NotificationAdmin';
import PhoneInput from 'react-phone-input-auto-format';
import { ProviderAdmin } from '../Components/Admin/ProviderAdmin';
import {
  BaseObjectConsumer,
  BaseObjectProvider,
  BaseObjectContext
} from '../Context/BaseObjectContext';
import {
  APIContextProvider,
  APIContextConsumer,
} from '../Context/APIProgressContext';


export class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: 'base',
      interfaces: [],
      visible: false,
      interfaceID: '',
      showBadge: false,
      inProgress: 0,
      finished: 0,
    };

  }

  setActiveComponent =  (component, data) => {
    if (data !== undefined) {

      if (this.state.activeComponent === 'base') {
        this.setState({ activeComponent: component, recordID: data.id });
      }

      if (component === 'baseRecord') {
        this.setState({ activeComponent: component, recordID: data.id });
      }
    } else {
      this.setState({ activeComponent: component });
    }
  }

  setBaseActiveIndex = (component, index, recordType) => {
    if (recordType === 'Community') {
      window.localStorage.removeItem('smartID');
      window.localStorage.removeItem('scramblerID');
      this.setState({
        activeComponent: 'community',
        recordType,
        interfaceID: 3,
      });
    } else if (recordType === 'Participant') {
      window.localStorage.removeItem('smartID');
      window.localStorage.removeItem('scramblerID');
      this.setState({ activeComponent: component, recordType, interfaceID: 2 });
    } else {
      this.setState({ activeComponent: component, activeBaseIndex: index });
    }
  }

  getInterfaces = id => {
    this.setState({ inProgress: this.state.inProgress + 1 });
    axios({
      method: 'get',
      url: url + 'Interface/' + id,
      headers: {
        api_key: key,
        AccessToken: this.props.token,
      },
    }).then(res => {
      let finished = this.state.finished;
      finished = finished + 1;
      // added by Jonathan 10/02/2019
      const newInterfacesArray = res.data.interfaces.reduce(( acc, item ) => {return [ ...acc, ...item.uiSets ];}, []);
      this.setState({ interfaces: newInterfacesArray, finished });
    });
  }

  handleButtonClick = () => {
    this.setState({ visible: !this.state.visible });
  }

  getPanes = () => {
    if (this.state.interfaces.length >= 1) {
      const data = this.state.interfaces;
      const panes = data.map(d => ({
        menuItem: d.label,
        render: () => <Tab.Pane>{d.label}</Tab.Pane>,
      }));
      if (this.state.panes === undefined) {
        this.setState({ panes });
      }
    }
  }

  listNotifications = notifications => {
    this.setState({ notifications });
  }

  checkNotifications = () => {
    if (
      this.state.notifications !== undefined &&
      this.state.profile !== undefined
    ) {
        let notifications = this.state.notifications;
        let profile = this.state.profile;
        let foundNotifications = _.filter(notifications, {
          notificationStatus: 12,
        });

        foundNotifications.forEach(notification => {
          if (
            notification.date.split('T')[0] >= profile.lastLogin.split('T')[0]
          ) {
            this.setState({ showBadge: true });
          } else {
            this.setState({ showBadge: false });
          }
        });
    }
  }

  getRecordInput = id => {
    axios({
      method: 'get',
      url: url + 'Record/Instance/' + id,
      headers: {
        api_key: key,
        AccessToken: this.props.token,
      },
    }).then(res => {
      const baseObject = res.data;
      this.setState({ baseObject, instanceID: id });
    });
  }


  getRecords = () => {

    if (this.state.recordID > 0)
    {
      axios({
        method: 'get',
        url: url + 'Record/' + this.state.recordID,
        headers: {
          api_key: key,
          AccessToken: this.props.token,
        },
      }).then(res => {
        let baseObj = res.data;
        let notes = res.data.notes;
        let finished = this.state.finished;
        finished = finished + 1;
        this.setState({ baseObject: baseObj, notes: notes, finished });
      });
    }
  }

  setBaseObjectState = newBaseObject => {
    const baseObject = newBaseObject;
    this.setState({ baseObject });
  }

  updateBaseObject = updatedObject => {
    const { baseObject, instanceID } = this.state;

    let inputData = [];
    let recordData = [];
    for (const [key, value] of Object.entries(updatedObject)) {
      for (const [k, v] of Object.entries(value)) {
        if (
          key < 9 ||
          key === 38 ||
          value.elementID < 9 ||
          value.elementID === 38
        ) {
          inputData.push(value);
          baseObject.inputData = inputData;
        } else {
          recordData.push(value);

          baseObject.records.forEach(record => {
            if (record.id === instanceID) {
              baseObject.records[0].inputData = recordData;
            }
          });
        }
      }
    }
    this.setState({ baseObject });

    this.submitDynamicForm(baseObject);
    if (this.state.activeComponent === 'newEvent') {
      this.pushRecord(recordData);
    } else return;
  }

  pushRecord = recordData => {

    const { baseObject } = this.state;
    let records = baseObject.records;
    let newRecordInstance = { id: 0, inputData: recordData };
    records.push(newRecordInstance);
    return records;
  }

  getProfile = () => {
    this.setState({ inProgress: this.state.inProgress + 1 });
    if (this.props.userID !== undefined) {
      const userID = this.props.userID;
      axios({
        method: 'get',
        url: url + 'user/' + userID,
        headers: {
          api_key: key,
          AccessToken: this.props.token,
        },
      }).then(res => {
        let profile = res.data;
        let finished = this.state.finished;
        finished = finished + 1;
        this.setState({ profile: profile, finished });
      });
    }
  }

  componentDidMount() {
    this.getProfile();
    window.localStorage.removeItem('smartID');
    window.localStorage.removeItem('scramblerID');
    window.localStorage.removeItem('DateFrom');
    window.localStorage.removeItem('DateTo');
    window.localStorage.removeItem('eventType');
    // window.localStorage.clear();
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (prevState.interfaceID !== this.state.interfaceID) {
      this.getInterfaces(this.state.interfaceID);
    }

    if (this.state.profile !== prevState.profile) {
      this.checkNotifications();
    }
    if(this.state.activeComponent === 'base') {
      let interfaceID = this.state.interfaceID;
      if(_.isString(interfaceID)) {
        this.setState({activeComponent: 'eventTypeCheck'});
      }
    }
  }

  postNewRecord = () => {
    // this.submitDynamicForm();
    this.setActiveComponent('base', 1);
  }

  updateSmartID = smartID => {
    if (this.state.baseObject !== undefined) {
      const baseObject = this.state.baseObject;
      baseObject.smartID = smartID;
      this.setState({ baseObject });
      if (smartID.length === 11) {
        axios({
          method: 'post',
          url: url + 'Record',
          headers: {
            api_key: key,
            AccessToken: this.props.token,
          },
          data: baseObject,
        }).then(res => {
          let baseObject = res.data;
          let notes = res.data.notes;
          this.setState({ baseObject, notes });
        });
      }
    } else {
      if (smartID.length === 11) {
        axios({
          method: 'post',
          url: url + 'Record',
          headers: {
            api_key: key,
            AccessToken: this.props.token,
          },
          data: { smartID: smartID },
        }).then(res => {
          const recordID = res.data.id;
          this.setState({ recordID });
          if (this.state.recordID !== undefined) {
            this.getRecords();
            this.setActiveComponent('baseRecord');
          }
        });
      }
    }
  }

  handleLogout = () => {
    setCookie('token', this.props.token, -1);
    window.location.reload(true);
  }

  handleOnUpdate = (e, { width }) => this.setState({ width })

  render() {
    const {
      notifications,
      profile,
      baseObject,
      activeComponent,
      width,
    } = this.state;

    let setActiveComponent = this.setActiveComponent;
    return (
      <Responsive fireOnMount onUpdate={this.handleOnUpdate}>
        <React.Fragment>
          <APIContextProvider
            values={{
              inProgress: this.state.inProgress,
              finished: this.state.finished,
            }}
          >
            {/* This is the header that appears across the whole app */}
            {typeof this.props.userID !== 'number' ? (
              this.props.history.push('/login')
            ) : (
              <React.Fragment />
            )}
            <div className="topBar">
              <Header
                render={({ title }) => (
                  <Responsive as={Menu}>
                    <Menu.Item position="left">
                      <Title
                        title={title}
                        setActiveComponent={this.setActiveComponent}
                      />
                    </Menu.Item>
                    <Menu.Item>
                      <APIContextConsumer>
                        {({ apiProgressCounter }) => (
                          apiProgressCounter > 0 ? (
                            <React.Fragment>
                              <Icon loading name="spinner" size="big" />
                            </React.Fragment>
                          ) : null
                        )}
                      </APIContextConsumer>
                    </Menu.Item>
                    <Responsive
                      as={Menu.Item}
                      position="right"
                      style={{ marginRight: '20px' }}
                    >
                      <Notifications
                        handleButtonClick={this.handleButtonClick}
                        notificationsList={notifications}
                        listNotifications={this.listNotifications}
                        showBadge={this.state.showBadge}
                        checkNotifications={ this.checkNotifications }
                      />
                      {profile !== undefined ? (
                        <Profile
                          render={({ profile }) => (
                            <React.Fragment>
                              <h3
                                style={{
                                  textDecoration: 'underline',
                                  textAlign: 'center',
                                }}
                              >
                                User Profile
                              </h3>
                              <Card.Header textAlign="center">
                                {profile.firstName} {profile.lastName}
                              </Card.Header>
                              <Card.Meta textAlign="center">
                                {profile.organization}
                              </Card.Meta>
                              <Card.Description textAlign="center">
                                Email: {profile.email}
                                <br />
                                Phone:{' '}
                                <PhoneInput
                                  value={profile.phone}
                                  style={{ border: 'none', background: 'none' }}
                                />
                              </Card.Description>
                              <br />
                              <Card.Content textAlign="center">
                                {/* <Button primary>Edit Profile</Button> */}
                                <Button
                                  negative
                                  onClick={() => this.handleLogout()}
                                >
                                  Logout
                                </Button>
                              </Card.Content>
                            </React.Fragment>
                          )}
                          profile={profile}
                        />
                      ) : (
                        <div />
                      )}
                      {this.state.profile !== undefined &&
                      this.state.profile.role.id < 3 ? (
                        <Settings
                          setActiveComponent={this.setActiveComponent}
                          profile={this.state.profile}
                        />
                      ) : (
                        <div />
                      )}
                    </Responsive>
                  </Responsive>
                )}
              />
            </div>
            {/* The Header Ends Here */}
            {/* This is the Start of the Body */}
            {width === undefined ? (
              <Dimmer active>
                <Loader
                  style={{ width: '30vw', height: '30vh' }}
                  size="massive"
                />
              </Dimmer>
            ) : (
              <APIContextConsumer>
              {({updateCounter}) => (
              <BaseObjectProvider
                values={{ baseObject, activeComponent, setActiveComponent}}
                updateCounter={updateCounter}
              >
                <BaseObjectConsumer>
                  {({
                    baseObject,
                    getRecordInput,
                    points,
                    getPointsAndElements,
                    inputData,
                    record,
                  }) => (
                    <div className="wrapper">
                      <div className="container">
                      {/* <NotificationsSidebar
                      visible={visible}
                      notificationsList={notifications}
                      listNotifications={this.listNotifications}
                      handleButtonClick={this.handleButtonClick}
                    > */}
                      {this.state.activeComponent === 'base' ||
                      this.state.activeComponent === 'community' ? (
                        <React.Fragment>
                          <Sidebar
                            panes={SidebarPanes}
                            activeComponent={this.state.activeComponent}
                            setActiveComponent={this.setActiveComponent}
                            setBaseActiveIndex={this.setBaseActiveIndex}
                            activeBaseIndex={this.state.activeBaseIndex}
                            profile={this.state.profile}
                            updateSmartID={this.updateSmartID}
                            recordID={baseObject !== undefined ? baseObject.id : undefined}
                            recordType={this.state.recordType}
                            width={this.state.width}
                          />
                        </React.Fragment>
                      ) : this.state.activeComponent === 'baseRecord' ? (
                        <Sidebar
                          panes={SidebarPanes}
                          activeComponent={this.state.activeComponent}
                          setActiveComponent={this.setActiveComponent}
                          recordID={baseObject !== undefined ? baseObject.id : undefined}
                          getRecordInput={getRecordInput}
                          setBaseActiveIndex={this.setBaseActiveIndex}
                          activeBaseIndex={this.state.activeBaseIndex}
                          profile={this.state.profile}
                          updateSmartID={this.updateSmartID}
                          recordType={this.state.recordType}
                          baseObject={baseObject}
                        />
                      ) : this.state.activeComponent === 'interface' ||
                        this.state.activeComponent === 'newEvent' ? (
                        <React.Fragment>
                          <div>
                            {this.state.interfaces.length === 0
                              ? this.getInterfaces(this.state.interfaceID)
                              : null}
                          </div>

                          <Interface
                            interfaces={this.state.interfaces}
                            activeComponent={this.state.activeComponent}
                            postNewRecord={this.postNewRecord}
                            inputData={inputData}
                            getRecords={this.getRecords}
                            baseObject={baseObject}
                            getRecordInput={getRecordInput}
                            setActiveComponent={this.setActiveComponent}
                            setBaseActiveIndex={this.setBaseActiveIndex}
                            activeBaseIndex={this.state.activeBaseIndex}
                            // updateBaseObject={this.updateBaseObject}
                            updateSmartID={this.updateSmartID}
                            points={points}
                            getPointsAndElements={getPointsAndElements}
                            record={record}
                            width={this.state.width}
                            profile={this.state.profile}
                            getInterfaces={this.getInterfaces}
                            interfaceID={this.state.interfaceID}
                          />
                        </React.Fragment>
                      ) : this.state.activeComponent === 'userAdmin' ? (
                        <APIContextConsumer>
                        {({updateCounter}) => (
                        <UserAdmin
                          setBaseActiveIndex={this.setBaseActiveIndex}
                          profile={this.state.profile}
                          render={({ content }) => <div>{content}</div>}
                          updateCounter={updateCounter}
                        />
                        )}
                        </APIContextConsumer>
                      ) : this.state.activeComponent === 'siteAdmin' ? (
                        <APIContextConsumer>
                        {({updateCounter}) => (
                        <SiteAdmin
                          setBaseActiveIndex={this.setBaseActiveIndex}
                          profile={this.state.profile}
                          render={({ content }) => <div>{content}</div>}
                          updateCounter={updateCounter}
                        />
                        )}
                        </APIContextConsumer>
                      ) : this.state.activeComponent === 'notificationAdmin' ? (
                        <APIContextConsumer>
                        {({updateCounter}) => (
                        <NotificationAdmin
                          notifications={this.state.notifications}
                          setBaseActiveIndex={this.setBaseActiveIndex}
                          profile={this.state.profile}
                          render={({ content }) => <div>{content}</div>}
                          updateCounter={updateCounter}
                          listNotifications={ this.listNotifications }
                        />
                        )}
                        </APIContextConsumer>
                      ) : this.state.activeComponent === 'providerAdmin' ? (
                        <APIContextConsumer>
                        {({updateCounter}) => (
                          <ProviderAdmin
                            notification={this.state.notifications}
                            setBaseActiveIndex={this.setBaseActiveIndex}
                            profile={this.state.profile}
                            render={({ content }) => <div>{content}</div>}
                            updateCounter={updateCounter}
                          />
                        )}
                        </APIContextConsumer>
                      ) : this.state.activeComponent === 'eventTypeCheck' ? (
                        <Sidebar
                          panes={SidebarPanes}
                          activeComponent={this.state.activeComponent}
                          setActiveComponent={this.setActiveComponent}
                          setBaseActiveIndex={this.setBaseActiveIndex}
                          activeBaseIndex={this.state.activeBaseIndex}
                          profile={this.state.profile}
                          updateSmartID={this.updateSmartID}
                        />
                      ) : null}
                    {/* </NotificationsSidebar> */}
                    </div>
                    </div>
                  )}
                </BaseObjectConsumer>
              </BaseObjectProvider>
            )}
            </APIContextConsumer>
            )}
            {/* The Body Ends Here */}

            <div style={{ height: '5em' }} />
          </APIContextProvider>
        </React.Fragment>
      </Responsive>
    );
  }
}

Main.contextType = BaseObjectContext;
