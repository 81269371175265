import React from "react";
import { key, url, setCookie } from '../Util';
import { Modal } from 'semantic-ui-react';

export default function(AppLogout) {
 return class LogoutTimeout extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        warningTime: 1000 * 60 * 10,
        signoutTime: 1000 * 60 * 15,
        warningModal: false
      };
    }

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for( let i in this.events ) {
        window.addEventListener( this.events[i], this.resetTimer );
      }

      this.setTimer();
    }

    clearTimer = () => {
      if( this.warnTimer ) clearTimeout( this.warnTimer );
      if( this.logoutTimer ) clearTimeout( this.logoutTimer );
    }

    setTimer = () => {
      this.warnTimer = setTimeout( this.warn, this.state.warningTime );
      this.logoutTimer = setTimeout( this.logout, this.state.signoutTime );
    };

    resetTimer = () => {
      if ( this.state.warningModal ) this.setState({ warningModal: false });
      this.clearTimer();
      this.setTimer();
    }

    destroyTimer = () => {
      const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];
      for ( let i in events ) {
        window.removeEventListener( events[i], this.resetTimer );
      }
    }

    warn = () => {
      this.setState({ warningModal: true });
    };

    logout = () => {
      this.setState({ warningModal: false });
      this.destroyTimer();
      setCookie( 'token', this.props.token, -1 );
      window.location.reload();
    };

    render() {
      return (
        <React.Fragment>
          <Modal
            open={ this.state.warningModal }
            size="mini"
          >
            <Modal.Content style={{ textAlign: 'center' }}>
              No activity has been detected for 10 minutes.
              <br />You will be logged out in 5 minutes if no further activity is detected.
            </Modal.Content>
          </Modal>
          <AppLogout {...this.props} />
        </React.Fragment>
      );
    }
  }
}
