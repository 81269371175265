import React from 'react';
import { Form, Header, Dropdown, Button, Icon } from 'semantic-ui-react';
import * as _ from 'lodash';

const genderOptions = [
  { key: 0, value: 1, text: 'Male' },
  { key: 1, value: 2, text: 'Female' },
  { key: 2, value: 3, text: 'Transgender/Other/Unknown' },
];

const dateOptions = [
  { key: 0, value: '01', text: '01 - January' },
  { key: 1, value: '02', text: '02 - February' },
  { key: 2, value: '03', text: '03 - March' },
  { key: 3, value: '04', text: '04 - April' },
  { key: 4, value: '05', text: '05 - May' },
  { key: 5, value: '06', text: '06 - June' },
  { key: 6, value: '07', text: '07 - July' },
  { key: 7, value: '08', text: '08 - August' },
  { key: 8, value: '09', text: '09 - September' },
  { key: 9, value: '10', text: '10 - October' },
  { key: 10, value: '11', text: '11 - November' },
  { key: 11, value: '12', text: '12 - December' },
];

function createYearOptions() {
  let yearStart;
  let yearEnd;

  let date = new Date();
  let year = date.getFullYear();

  yearStart = year;
  yearEnd = year - 90;

  let numberOfYears = yearStart - yearEnd + 1;
  let yearOptions = [];
  for ( let i = 0; i < numberOfYears; i++ ) {
    if (yearEnd++ <= yearStart) {
      yearOptions.push({
        key: i,
        value: yearStart - i,
        text: (yearStart - i).toString(),
      });
    }
  }
  return yearOptions;
}

export class NewSID extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sid: {
        FIFN: '',
        FILN: '',
        MMFN: '',
        MMLN: '',
        MONTH: '',
        YEAR: '',
        GCODE: '',
      },
      smartID: '',
      formHeader: 'New Smart ID',
      yearOptions: []
    };
  }

  componentDidMount() {
    if (this.props.smartID !== undefined) {
      this.setState({
        smartID: this.props.smartID,
        formHeader: 'Update Smart ID',
      });
    } else if (this.props.smartID === null) {
      this.setState({
        smartID: 'Error: Smart ID Not Set',
        formHeader: 'Update Smart ID',
      });
    }
    let yearOptions = createYearOptions();
    this.setState({ yearOptions });
  }

  createSID = (event, { name, value }) => {
    const sid = { ...this.state.sid, [name]: value };
    this.setState({ sid });
    let concatSID = '';
    for (var key in sid) {
      concatSID = concatSID.concat(sid[key]).toUpperCase();
      if (concatSID.length >= 11) {
        this.props.toggleConfirmPost(true, concatSID);
      }
    }
    this.setState({ concatSID });
  }

  render() {
    const { smartID, concatSID, formHeader, yearOptions } = this.state;
    return (
      <React.Fragment>
        <Header dividing>
          <Icon name="add circle" />
          {formHeader}
        </Header>
        <Form size="small" widths="equal">
          <div className="smartIDViz">
            <h3>{smartID ? `Current Smart ID: ${smartID}` : null}</h3>
            <h3 style={{ display: 'inline-block' }}>{concatSID ? `New Smart ID: ${concatSID}` : null}</h3>
          </div>
          <div className="SmartIDForm">
            <Form.Group widths="equal">
              <Form.Input
                name="FIFN"
                label="First Initial (First Name)"
                value={this.state.sid.FIFN.toUpperCase()}
                onChange={this.createSID}
                maxLength="1"
                autoComplete="off"
              />
              <Form.Input
                name="FILN"
                label="First Initial (Last Name)"
                value={this.state.sid.FILN.toUpperCase()}
                onChange={this.createSID}
                maxLength="1"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                name="MMFN"
                label="Mother's First Initial (First Name)"
                value={this.state.sid.MMFN.toUpperCase()}
                onChange={this.createSID}
                maxLength="1"
                autoComplete="off"
              />
              <label />
              <Form.Input
                name="MMLN"
                label="Mother's First Initial (Maiden Name)"
                value={this.state.sid.MMLN.toUpperCase()}
                onChange={this.createSID}
                maxLength="1"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                name="MONTH"
                selection
                options={dateOptions}
                label="Birth Month"
                value={this.state.sid.MONTH}
                onChange={this.createSID}
              />
              <Form.Dropdown
                name="YEAR"
                selection
                options={yearOptions}
                label="Birth Year"
                value={this.state.sid.YEAR}
                onChange={this.createSID}
              />
              <Form.Dropdown
                name="GCODE"
                selection
                options={genderOptions}
                label="Gender"
                value={this.state.sid.GCODE}
                onChange={this.createSID}
              />
            </Form.Group>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
