import React from 'react';
import { Form, Header, Button, Icon } from 'semantic-ui-react';
import PhoneInput from "react-phone-input-auto-format";

export class SiteForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = { confirmDelete: false};
  }

  changeConfirmDelete = () => {
    this.setState({confirmDelete: !this.state.confirmDelete});
  }

  render() {
    const newSite = (
      <NewSite
        handleNewSite={this.props.handleNewSite}
        site={this.props.site}
      />
    );
    const editSite = (
      <EditSite
        site={this.props.site}
        handleSiteChange={this.props.handleSiteChange}
        deleteSite={this.props.deleteSite}
        changeConfirmDelete={this.changeConfirmDelete}
        confirmDelete={this.state.confirmDelete}
      />
    );
    return (
      <React.Fragment>
        {this.props.render({
          newSite: newSite,
          editSite: editSite,
        })}
      </React.Fragment>
    );
  }
}

export class NewSite extends React.Component {
  constructor(props) {
    super(props);


  }
  render() {
  return (
    <React.Fragment>
     <Form.Group>
      <Form.Input
        name="name"
        label="Site Name"
        width={16}
        value={this.props.site.name}
        onChange={this.props.handleNewSite}
      />
      </Form.Group>
      <Form.Group>
      <Form.Input
        name="description"
        width={16}
        label="Site Description"
        value={this.props.site.description}
        onChange={this.props.handleNewSite}
      />
      </Form.Group>
      <Form.Group>
      <Form.Input
        name="contactName"
        width={16}
        label="Contact Name"
        value={this.props.site.contactName}
        onChange={this.props.handleNewSite}
      />
      </Form.Group>
      <Form.Group>
      <Form.Input
        name="contactEmail"
        width={16}
        label="Contact Email"
        value={this.props.site.contactEmail}
        onChange={this.props.handleNewSite}
      />
      </Form.Group>
      <Form.Group>
      <PhoneInput
        name="contactPhone"
        width={16}
        label="Contact Phone"
        inputComponent={Form.Input}
        value={this.props.site.contactPhone}
        onChange={(event, name) => this.props.handleNewSite(event, {name})}
      />
      </Form.Group>
    </React.Fragment>
  );
}};

const EditSite = props => {
  return (
    <React.Fragment>
      <Form.Group>
      <Form.Input
        name="name"
        label="Site Name"
        width={16}
        value={props.site.name}
        onChange={props.handleSiteChange}
      />
      </Form.Group>
      <Form.Group>
      <Form.Input
        name="description"
        width={16}
        label="Site Description"
        value={props.site.description}
        onChange={props.handleSiteChange}
      />
      </Form.Group>
      <Form.Input
        name="contactName"
        width={16}
        label="Contact Name"
        value={props.site.contactName}
        onChange={props.handleSiteChange}
      />
     <Form.Group>
      <Form.Input
        name="contactEmail"
        label="Contact Email"
        width={16}
        value={props.site.contactEmail}
        onChange={props.handleSiteChange}
      />
      </Form.Group>
      <Form.Group>
      <PhoneInput
        name="contactPhone"
        label="Contact Phone"
        width={16}
        inputComponent={Form.Input}
        value={props.site.contactPhone !== null ? props.site.contactPhone : ""}
        onChange={(event, name) => props.handleSiteChange(event, {name})}
      />
      </Form.Group>
      <Header dividing>Delete Site</Header>
      <Button
        animated="vertical"
        color="red"
        inverted
        style={{ width: '50%', left: '25%', right: '25%' }}
        onClick={props.confirmDelete == false ? props.changeConfirmDelete : props.confirmDelete == true ? props.deleteSite : null}
      >
        <Button.Content hidden>{props.confirmDelete == false ? 'Delete Site' : props.confirmDelete == true ? 'Are You Sure?' : null}</Button.Content>
        <Button.Content visible>
          <Icon name="close" />
        </Button.Content>
      </Button>
    </React.Fragment>
  );
};
