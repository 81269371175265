import React from 'react';
import { List, Modal } from 'semantic-ui-react';
import { validateEmail, validatePhone } from '../../Util';

const ProviderError = ({ open, close, provider }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="small"
    >
      <Modal.Header>
        One or more required fields are missing
      </Modal.Header>
      <Modal.Content>
        <List bulleted>
          <List.Header>
            Please confirm that you have entered the following fields and that all fields are valid:
          </List.Header>
          <br />
          <List.Content>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ provider.name ? 'green' : 'red' }
                name={ provider.name ? 'check' : 'times' }
              />
              &nbsp;
              Name
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ provider.description ? 'green' : 'red' }
                name={ provider.description ? 'check' : 'times' }
              />
              &nbsp;
              Description
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ provider.contactName ? 'green' : 'red' }
                name={ provider.contactName ? 'check' : 'times' }
              />
              &nbsp;
              Contact Name
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ validateEmail( provider.contactEmail ) ? 'green' : 'red' }
                name={ validateEmail( provider.contactEmail ) ? 'check' : 'times' }
              />
              &nbsp;
              Valid Contact Email
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ validatePhone( provider.contactPhone ) ? 'green' : 'red' }
                name={ validatePhone( provider.contactPhone ) ? 'check' : 'times' }
              />
              &nbsp;
              Contact Phone
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ provider.login ? 'green' : 'red' }
                name={ provider.login ? 'check' : 'times' }
              />
              &nbsp;
              Login
            </List.Item>
            <List.Item style={{ display: 'flex', alignItems: 'center' }}>
              <List.Icon
                color={ provider.providerTypes.length ? 'green' : 'red' }
                name={ provider.providerTypes.length ? 'check' : 'times' }
              />
              &nbsp;
              At Least One Provider Type
            </List.Item>
          </List.Content>
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default ProviderError;