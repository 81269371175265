import React from 'react';
import { TabHeader } from '../TabHeader/TabHeader';
import ReactTable from 'react-table';
import {
  Segment,
  Modal,
  Button,
  Header,
  Form,
  Label,
  Divider,
  Icon,
} from 'semantic-ui-react';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import _ from 'lodash';

export class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stop: false,
      columns: [],
      data: [],
      loading: false,
      error: false,
      errorResponse: '',
      substance: '',
      substanceOptions: [],
    };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }

    axios({
      method: 'get',
      url: url + 'Reports',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      let data = res.data;
      let length = res.data.length;
      this.createColumns(res.data);
      this.setState({ data, length });
    });

    axios({
      method: 'get',
      url: url + 'Sites',
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      let sites = res.data;
      this.setState({ sites });
    });
    let substanceLookupId = 9;
    axios({
      method: 'get',
      url: url + `Interface/dataElement/${substanceLookupId}`,
      headers: {
        api_key: key,
        AccessToken: getCookie('token'),
      },
    }).then(res => {
      let substanceOptions = res.data.options;
      this.setState({ substanceOptions });
    });

    if (this.props.profile !== undefined) {
      if( this.props.profile.role.id == 2 || this.props.profile.role.id == 3 || this.props.profile.role.id == 4 ) {
        let site = this.props.profile.site;
        this.setState({ site });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open == true && this.state.open == false) {
      this.setState({
        url: null,
        dateFrom: '',
        dateTo: '',
        errorResponse: '',
        substance: '',
      });

      this.createColumns(this.state.data);
    }

    if (this.state.sites !== prevState.sites && this.props.profile !== undefined) {
      if ( this.props.profile.role.id == 1 ) {
      }
      else if ( this.props.profile.role.id == 2 || this.props.profile.role.id == 3 || this.props.profile.role.id == 4 ) {
        let site = this.props.profile.site;
        this.setState({ site });
      }
    }
  }

  createColumns = data => {
    let keys = data.map(d => Object.keys(d));
    let keyLength = keys[0].length;
    let columns = [];
    let button = {
      Header: '',
      accessor: 'id',
      Cell: row => (
        <Button row={row} onClick={() => this.reportModal(row)}>
          Run Report
        </Button>
      ),
    };
    for (let i = 0; i < keyLength; i++) {
      if (keys[0][i] !== 'id') {
        if (keys[0][i] != 'filter')
          columns.push({ Header: keys[0][i], accessor: keys[0][i] });
      }
    }

    columns.push(button);
    this.setState({ columns });
  }

  reportModal = details => {
    let options = this.state.sites.map(site => ({
      key: site.id,
      text: site.name,
      value: site.id,
    }));

    let substanceOptions = this.state.substanceOptions.map(substance => ({
      key: substance.id,
      text: substance.label,
      value: substance.id,
    }));

    let modalDetails = {
      id: details.original.id,
      name: details.original.name,
      description: details.original.description,
      options,
      substanceOptions,
      filter: _.parseInt(details.original.filter),
    };

    this.setState({ open: true, modalDetails });
  }

  onToChange = value => {
    const dateTo = value;
    this.setState({ dateTo, error: false, errorResponse: '' });
  }

  onFromChange = value => {
    const dateFrom = value;
    this.setState({ dateFrom, error: false, errorResponse: '' });
  }

  onSiteChange = (event, { name, value }) => {
    const site = value;
    this.setState({ site, error: false, errorResponse: '' });
  }

  onFilterChange = (event, { name, value }) => {
    const substance = value;
    this.setState({ substance });
  }

  runReport = () => {
    this.setState({ loading: !this.state.loading });
    let site;
    if (_.isObject(this.state.site) == true) {
      site = this.state.site.id;
    } else {
      site = this.state.site;
    }
    const reportID = this.state.modalDetails.id;

    if (_.isInteger(this.state.substance) == true) {
      axios({
        method: 'post',
        url: url + 'report/execute?ReportID=' + reportID + '&ReportType=pdf',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: {
          values: [
            {
              name: 'SiteID',
              value: site,
              type: 'integer',
            },
            {
              name: 'DateFrom',
              value: moment(this.state.dateFrom).format('MM/DD/YYYY'),
              type: 'date',
            },
            {
              name: 'DateTo',
              value: moment(this.state.dateTo).format('MM/DD/YYYY'),
              type: 'date',
            },
            {
              name: 'Parm1',
              value: this.state.substance,
              type: 'integer',
            },
          ],
        },
      })
        .then(res => {
          this.setState({
            loading: false,
            url: res.data.url,
            dateFrom: undefined,
            dateTo: undefined,
            // site: undefined,
            substance: '',
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({
            loading: false,
            error: true,
            errorResponse: error.response,
            dateFrom: undefined,
            dateTo: undefined,
            // site: undefined,
            substance: '',
          });
        });
    } else {

      let site;
      if (_.isObject(this.state.site) == true) {
        console.log(this.state.site);
        site = this.state.site.id;
      } else {
        site = this.state.site;
      }

      axios({
        method: 'post',
        url: url + 'report/execute?ReportID=' + reportID + '&ReportType=pdf',
        headers: {
          api_key: key,
          AccessToken: getCookie('token'),
        },
        data: {
          values: [
            {
              name: 'SiteID',
              value: site,
              type: 'integer',
            },
            {
              name: 'DateFrom',
              value: moment(this.state.dateFrom).format('MM/DD/YYYY'),
              type: 'date',
            },
            {
              name: 'DateTo',
              value: moment(this.state.dateTo).format('MM/DD/YYYY'),
              type: 'date',
            },
          ],
        },
      })
        .then(res => {
          this.setState({
            loading: false,
            url: res.data.url,
            dateFrom: undefined,
            dateTo: undefined,
            // site: undefined,
            substance: '',
          });
        })
        .catch(error =>
          this.setState({
            loading: false,
            error: true,
            url: null,
            errorResponse: error.response,
            dateFrom: undefined,
            dateTo: undefined,
            // site: undefined,
            substance: '',
          })
        );
    }
  }

  openReport = () => {
    window.open(this.state.url);
    this.setState({ open: false, url: null });
  }

  onClose = () => {
    this.setState({
      url: null,
      dateFrom: undefined,
      dateTo: undefined,
      errorResponse: '',
      open: false,
      error: false,
      // site: undefined,
    });
  }

  render() {
    const content = 'Reports';
    const {
      columns,
      data,
      modalDetails,
      dateFrom,
      dateTo,
      site,
      loading,
      substance,
      substanceOptions,
      errorResponse,
    } = this.state;
    const { profile } = this.props;

    const modal = (
      <Modal
        open={this.state.open}
        closeIcon={
          <Button
            icon="close"
            color="red"
            onClick={() =>
              this.setState({ open: false, error: false, errorResponse: '' })
            }
            style={{ float: 'right', marginTop: '-1em', marginRight: '-20px' }}
          />
        }
        onClose={this.handleClose}
        centered
        closeOnDocumentClick
      >
        <Modal.Header>Run Reports</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>
              {modalDetails !== undefined ? modalDetails.name : ''}
            </Header>
            <p>{modalDetails !== undefined ? modalDetails.description : ''}</p>
          </Modal.Description>
          <Form style={{ marginTop: '2em' }}>
            <Form.Group inline>
              <Form.Field>
                <DatePicker
                  onChange={this.onFromChange}
                  selected={dateFrom}
                  placeholderText={'MM/DD/YYYY'}
                  isClearable
                  customInput={
                    <MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      keepCharPositions= {true}
                      guide = {true}
                    />
                  }
                />
              </Form.Field>
              <Label style={{ 'margin-right': '15px' }}>To</Label>
              <Form.Field>
                <DatePicker
                  onChange={this.onToChange}
                  selected={dateTo}
                  placeholderText={'MM/DD/YYYY'}
                  isClearable
                  customInput={
                    <MaskedInput
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      keepCharPositions= {true}
                      guide = {true}
                    />
                  }
                />
              </Form.Field>
            </Form.Group>
            {profile.role !== undefined && profile.role.id == 1 ? (
              <Form.Dropdown
                onChange={this.onSiteChange}
                label="Select Site"
                name="site"
                value={ site }
                selection
                width={4}
                options={
                  modalDetails !== undefined ? modalDetails.options : null
                }
              />
            ) : (
              <Form.Input
                label="Site"
                name="site"
                value={
                  this.state.site !== undefined ? this.state.site.name : null
                }
                width={4}
              />
            )}
            {modalDetails !== undefined &&
            _.isInteger(modalDetails.filter) == true ? (
              <Form.Dropdown
                onChange={this.onFilterChange}
                label="Select Substance"
                name="substance"
                value={substance}
                selection
                width={4}
                options={
                  modalDetails !== undefined
                    ? modalDetails.substanceOptions
                    : null
                }
              />
            ) : null}

            <Divider />
            <Button
              loading={loading}
              positive={
                this.state.url !== undefined && this.state.url !== null
                  ? true
                  : false
              }
              onClick={
                this.state.url === undefined || this.state.url === null
                  ? this.runReport
                  : this.openReport
              }
            >
              <Icon name="file pdf" />
              {this.state.url === undefined || this.state.url === null
                ? 'Run Report'
                : 'Open Report'}
            </Button>
            {this.state.error === true ? (
              <Header textAlign="center" style={{ color: 'red' }}>
                Error Running Report, Please Try Again
              </Header>
            ) : null}
          </Form>
        </Modal.Content>
      </Modal>
    );

    return (
      <div>
        <TabHeader titleLeft="Reports" titleRight={profile.site.name} />

        <Segment>
          <ReactTable
            columns={columns}
            data={data}
            style={{
              textAlign: 'left',
              clear: 'right',
              padding: '5px',
              verticalAlign: 'middle',
            }}
            className="-striped -highlight tblReports"
          />
          {modal}
        </Segment>
      </div>
    );
  }
}
