import React from 'react';
import {
  Form,
  Radio,
  Input,
  Segment,
  Dropdown,
  Loader,
  Dimmer,
  TextArea,
  Label,
  Button,
  Table,
  Modal,
} from 'semantic-ui-react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';
import { checkCookie } from '../../Util';


const numberInputRef = React.createRef();
export class IsCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalOpen: false,
      editOpen: false,
      collectionElements: [],
      newRow: [],
      tableState: [],
      initiateDelete: false,
      confirmDelete: false,
      blurred: false,
      previousValues: [],
      isChecked: false,
      newestRow: [],
      collectionErrorOpen: false
    };
  }

  componentDidMount() {
    if (
      this.props.inputData !== undefined &&
      this.props.elements !== undefined
    ) {
      this.createCollectionElements();
      numberInputRef.current = 0;
    }

    if (this.props.isReadOnly !== undefined) {
      let isReadOnly = this.props.isReadOnly;
      this.setState({ isReadOnly });
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.collectionElements !== prevState.collectionElements) {
      this.createTableData();
      this.createCollectionElements();
    }
    if (prevProps !== this.props) {
    } else if (prevProps.baseObject !== this.props.baseObject) {
      this.createCollectionElements();
    }

    if (
      prevProps.inputData !== this.props.inputData ||
      prevState.inputData !== this.state.inputData
    ) {
      this.createCollectionElements();
      this.setState({ inputData: this.props.inputData });
    }

    if(prevState.tableState !== this.state.tableState) {

      this.setState(state => {
        const tableState = state.tableState;
        return {tableState}
      })


    }
  }

  initiateDelete = (removeCollectionElements, row, index) => {
    if (this.state.initiateDelete === false) {
      this.setState({ initiateDelete: index });
    } else {
      let index = this.state.index;
      let tableState = this.state.tableState;
      let tableStateEntry = tableState[index];
      let confirmDelete = this.state.confirmDelete;
      confirmDelete = true;

      this.setState({initiateDelete: false, confirmDelete: index },
        removeCollectionElements(row, true)
      );
      if (confirmDelete === true) {
        tableState.splice(this.state.index, 1);
        confirmDelete = false;
        this.setState({
          tableState,
          initiateDelete: false,
          row: [],
          newRow: [],
          confirmDelete,
          numberRef: null,
        });
        // this.createCollectionElements()
        // this.createCollectionWithPropsTable()
      }
    }
  }

  createCollectionElements = () => {
    let inputData = this.props.inputData;
    let elements = this.props.elements;
    let tableState = this.state.tableState;
    let collectionElements = this.state.collectionElements;

    if (
      inputData !== undefined
      && collectionElements.length < elements.length
    ) {
      let newElements = _.clone(elements);
      this.setState({ collectionElements: newElements });
    }

    if (
      _.isEmpty(inputData) == false &&
      inputData.length > 1 &&
      this.props.collection == true
    ) {
      let tableData = [];
      let newInput;
      for (let i = 0; i < inputData.length; i++) {
        for (let j = 0; j < elements.length; j++) {
          if (inputData[i].elementID == elements[j].id) {
            newInput = Object.assign({}, elements[j]);
            newInput = Object.assign(newInput, inputData[i]);

            let elementValue;
            if (newInput.options.length > 0) {
              let findElementValue = _.find(newInput.options, {
                id: newInput.optionID,
              });
              if (findElementValue !== undefined) {
                elementValue = findElementValue.label;
              }
            } else {
              elementValue = newInput.value;
            }
            newInput.elementValue = elementValue;
          }

          let includes = _.includes(tableData, newInput);
          if (includes == false && newInput !== undefined) {
            tableData.push(newInput);
          }
        }
      }
      // let sorted = _.sortBy(tableData, 'seq');
      let sorted = _.sortBy(tableData, ['seq']);
      let grouped = _.groupBy(sorted, s => s.seq);
      let newTableState = Object.values(grouped);

      this.setState({ tableState: newTableState, loading: false });
    }
  }

  createTableData = () => {
    if (
      this.props.elements !== undefined &&
      this.state.collectionElements !== undefined
    ) {
      if (this.state.collectionElements.length > 0) {
        let elements = this.props.elements;
        let collectionElements = this.state.collectionElements;

        let labels;
        labels = [];
        elements.map(el => {
          let includes = _.includes(labels, el.label);
          if (includes === false && el.label !== undefined) {
            labels.push(el.label);
          }
        });
        labels = labels;
        let length = labels.length;
        if (this.state.labels == undefined || this.state.columns == 0) {
          this.setState({ labels, columns: length });
        }
      }
    }
  }

  handleChange = (event, {id, value}) => {
    let numberRef = this.state.numberRef;
    let row = this.state.row;
    let newRow = this.state.newRow;
    if(this.state.editOpen == true) {
      numberRef = event.target.value;
      let find = _.find(row, {elementID: id});
      if(find !== undefined) {
        find.value = value;
        find.elementValue = value;
      }
    }

    if(this.state.modalOpen == true) {
      numberRef = event.target.value;
      let find = _.find(newRow, {elementID: id});
      if(find !== undefined) {
        find.value = value;
        find.elementValue = value;
      }
    }

  }

  handleBlur = event => {
    event.preventDefault();
    let elId = event.target.id.toString();
    let element = document.getElementById(elId);

    element.addEventListener('blur', e => {
      numberInputRef.current = e.target.value;
      this.setState({ numberRef: e.target.value });

      this.handleInputRef(
        e,
        element.name,
        e.target.value,
        0,
        Number(e.target.id),
        0,
        element.type
      );
    });
  }

  handleInputRef = (event, name, value, points, id, seq, type) => {

    if (this.state.modalOpen == true) {
      let newElement;
      let newRow = this.state.newRow;
      let tableState = this.state.tableState;
      let foundElement = _.find(newRow, { id: id });

      if (foundElement !== undefined) {
        let foundProperties = _.find(this.state.collectionElements, { id: id });

        newElement = Object.assign(foundElement, foundProperties);
        let elementValue;
        if (foundProperties.options.length < 1) {
          elementValue = value;
        }
        let newSeq = this.state.tableState.length + 1;

        newElement.elementID = id;
        newElement.value = value;
        newElement.elementValue = value;
        newElement.seq = newSeq;
        let newestRow = this.state.newestRow;
        for(let i=0; i<newRow.length; i++){
          // console.log(newRow[i])
          if( !_.isEmpty(newRow[i].elementValue) && newRow[i].elementValue !== undefined) {
            newestRow = _.concat(newestRow, newRow[i]);
          }
        }

        if(newestRow.length == this.state.labels.length) {
          this.setState({isComplete: true});
        }

        this.setState({ newRow, newestRow });
      }
    }

    /* This is where number inputs get updated in edit*/
    if (this.state.row !== undefined && this.state.modalOpen == false) {
      let row = this.state.row;
      let foundValue = _.find(row, { elementID: id });

      this.numberInputRef = value;
      if (foundValue !== undefined) {
        foundValue.value = value;
        foundValue.elementValue = value;
        this.setState({ numberRef: value, row });
      }
    }
  }

  onChange = (event, { name, value, points, id, seq, type, checked }) => {
    /* this is where the changes from the edit modal happen */

    if (this.state.row !== undefined && this.state.modalOpen == false) {
      let row = this.state.row;
      let foundValue = _.find(row, { elementID: id });
      if (foundValue !== undefined && foundValue.options.length > 0) {
        let elementValue = _.find(foundValue.options, { id: value });

        foundValue.optionID = value;
        if ( foundValue.elementValue !== undefined ) {
          foundValue.elementValue = elementValue.label;
        }
        this.setState({ row });
      } else if (
        foundValue !== undefined &&
        foundValue.optionID === 0 &&
        this.state.modalOpen == false
      ) {
        foundValue.value = value;
        foundValue.elementValue = value;

        this.setState({ row });
      } else if (
        foundValue === undefined &&
        this.state.modalOpen == false
      ) {
        let findValue = _.find(this.state.row, function(r) {
          if (r !== undefined) {
            return r;
          }
        });

        let newValue = Object.assign({}, findValue);
        let newElement;
        let elements = _.clone(this.props.elements)
        let element = _.find(elements, { id: id });
        newElement = Object.assign({}, element);
        let elementValue = _.find(newElement.options, { id: value });
        let newerValue = Object.assign(newValue, element);

        newValue.elementID = id;
        newValue.id = id;
        newValue.optionID = _.isInteger(value) === true ? value : 0;
        newValue.value = _.isString(value) === true ? value : '';
        newValue.elementValue =
        elementValue !== undefined ? elementValue.label : value;
        newValue.label = '';

        row = _.concat(row, newValue);
        this.setState({ row });
      } else {
        console.log('Something is unaccounted for');
      }
    }

    if (this.state.editOpen === false) {
      /* This is where the changes from the Add Modal Happen */
      let newElement;
      let newRow = this.state.newRow;
      let tableState = this.state.tableState;
      let foundElement = _.find(newRow, { id: id });

      if (foundElement !== undefined) {
        let foundProperties = _.find(this.state.collectionElements, { id: id });
        newElement = Object.assign(foundElement, foundProperties);

        let elementValue;
        if (
          foundProperties !== undefined &&
          foundProperties.options.length > 0
        ) {
          elementValue = _.find(foundProperties.options, { id: value });
          elementValue = elementValue !== undefined ? elementValue.label : '';
        } else {
          if (foundProperties !== undefined) {
            elementValue = _.find(foundProperties.options, { id: value });
            elementValue = elementValue !== undefined ? elementValue.label : '';
          } else {
            elementValue = value;
          }
        }

        let newSeq = this.state.tableState.length + 1;
        newElement.elementID = id;
        newElement.optionID = _.isInteger(value) === true ? value : 0;
        newElement.value = _.isString(value) === true ? value : '';
        newElement.elementValue = elementValue;
        newElement.seq = newSeq;

        // let newestRow = this.state.newRow;
        // /// PROBLEM!!!!!!!
        // // for(let i=0; i<newRow.length; i++){
        // //   if(!_.isEmpty(newRow[i].elementValue) && newRow[i].elementValue !== undefined) {
        // //     newestRow = _.concat(newestRow, newRow[i]);
        // //   }
        // // }
        //
        // if(newestRow.length == this.state.labels.length) {
        //   this.setState({isComplete: true})
        // }
        // this.setState({ newRow, newestRow });

        let newestRow = this.state.newRow.reduce( (acc, row) => {
          if (row.elementValue) {
            return [ ...acc, row ];
          }
          return acc;
        }, []);

        if ( newestRow.length == this.state.labels.length ) {
          this.setState({ isComplete: true })
        }
        this.setState({ newRow, newestRow });
      }

      if (foundElement !== undefined) {
        let elementValue;

        if (foundElement.options.length > 0 && foundElement.type !== 7 ) {
          elementValue = _.find(foundElement.options, { id: value });
          if ( elementValue !== undefined ) {
            elementValue = elementValue.label;
          }
        } else {
          elementValue = value;
        }
        foundElement.optionID = _.isInteger(value) === true ? value : 0;
        foundElement.value = _.isString(value) === true ? value : '';
        foundElement.elementValue = elementValue;
        this.setState({ newRow });
      }
    }
  }

  handleModalOpen = () => {

    this.numberInputRef = null;

    let newRowElements = _.clone(this.props.elements);
    let filter = newRowElements.filter(element => element.type == 7);
    if(filter !== undefined && !_.isEmpty(filter)) {
      this.setState({isChecked: false});
      let findNegative = _.find(filter[0].options, {label: 'No'});

      if(findNegative !== undefined) {
        let findNewRowElement = _.find(newRowElements, {id: filter[0].id});
        findNewRowElement.optionID = findNegative.id;
        findNewRowElement.elementID = filter[0].id;
        findNewRowElement.elementValue = findNegative.label;
        findNewRowElement.seq = this.state.tableState.length + 1;
      }
    }

    let resetFilter = newRowElements.filter(element => element.type !== 7);
    if(resetFilter !== undefined) {
      resetFilter.forEach(element => {
        element.optionID = 0;
        element.value = '';
        element.elementValue = '';
        element.seq = this.state.tableState.length + 1;
      });
    }

    this.setState({ modalOpen: true, isComplete: false, newRow: newRowElements, numberRef: null});
  }

  handleModalClose = (argument, modalType, handleCollectionSubmission) => {

    if (argument === 'cancel') {
      let tableState = this.state.tableState;
      // if (this.state.newRow.length === this.state.labels.length) {
      //   tableState.pop();
      // }
      this.setState({
        modalOpen: false,
        newRow: [],
        row: [],
        tableState,
        numberRef: null,
        isChecked: false,
        newestRow: []
      });
      this.numberInputRef = null;
    }

    if (argument === 'save') {
      let tableState = this.state.tableState;

      handleCollectionSubmission(
        modalType,
        this.state.tableState,
        this.props.activeComponent,
        this.state.newRow
      );

      this.setState({
        modalOpen: false,
        newRow: [],
        row: [],
        blurred: false,
        numberRef: null,
        isChecked: false,
        newestRow: []
      });
      this.numberInputRef = null;
    }
  }

  findValue = (response, element, id, row, index) => {


    if (
      this.state.row !== undefined &&
      _.isArray(this.state.row) === true &&
      index !== undefined
    ) {
      let returnValue;
      this.state.row.map(r => {
        if (r.options.length > 0 && r.id === id) {
          returnValue = r.optionID;
        } else if (r.options.length == 0 && r.id === id) {
          returnValue = r.value;
        }
      });
      return returnValue;
    } else if (
      this.state.newRow !== undefined &&
      this.state.modalOpen === true
    ) {
      let returnValue;
      this.state.newRow.map(r => {
        if (r.optionID > 0 && r.id === id) {
          returnValue = r.optionID;
        } else if (r.optionID == 0 && r.id === id) {
          returnValue = r.value;
        }
      });
      return returnValue;
    }
  }

  handleEditClose =  (argument, modalType, handleCollectionSubmission) => {
    if (argument == 'cancel') {
    this.setState({ editOpen: false});
      this.setState({
        previousValues: [],
        newValues: [],
        row: [],
        numberRef: null,
        newRow: [],
        newRowState: [],
        isChecked: false,
        newestRow: []
      });
      this.numberInputRef = null;
    }

    if (argument == 'save') {
      let row = this.state.row;
      let numberRef = this.state.numberRef;
      let tableState = this.state.tableState;
      let index = this.state.index;
      // tableState[index] = row;
      tableState = tableState;
      handleCollectionSubmission(
        modalType,
        tableState,
        this.props.activeComponent,
        row,
        index
      );
      this.setState({
        editOpen: false,
        tableState,
        row: [],
        numberRef: null,
        newRow: [],
        newRowState: [],
        isChecked: false,
        newestRow: []
      });
      this.numberInputRef = null;
    }
  }
  handleEditOpen = row => {
    if (this.state.initiateDelete === false) {
      let findNumberRef;
      let numberRef = this.state.numberRef;
      row.map(r => {
        if (r.options.length == 0) {
          findNumberRef = r;
          findNumberRef.value = findNumberRef.elementValue;
        }

      });
      if (findNumberRef !== undefined) {
        numberInputRef.current = findNumberRef.elementValue;
        numberRef = numberInputRef.current;
      }
      let previousValues = _.clone(row);
      let findCheckedState = _.find(row, {type: 7});
      if(findCheckedState !== undefined) {
        let findCheckedOption = _.find(findCheckedState.options, {label: 'No'});
        let findCheckedOptionID = findCheckedOption.id;
        if(findCheckedOptionID !== findCheckedState.optionID) {
          // console.log('true', findCheckedOptionID, findCheckedState.optionID)
          this.setState({isChecked: true, editOpen: true, isDisabled: false, row: row, numberRef, previousValues});
        } else {
          // console.log('false', findCheckedOptionID, findCheckedState.optionID)
          this.setState({isChecked: false, editOpen: true, isDisabled: false, row: row, numberRef, previousValues});
        }
      } else {
        this.setState({editOpen: true, row: row, numberRef, previousValues, isDisabled: false})
      }
    } else {
      this.setState({ initiateDelete: false });
    }
  }

  createCollectionWithPropsTable = (collection, elements, responses) => {
    const tableState = collection;

    return (
      <BaseObjectConsumer>
        {({
          handleCollectionSubmission,
          inputData,
          recordInput,
          removeCollectionElements,
        }) => (
          <React.Fragment>
          { console.log( this.state )}
            <Modal
              trigger={
                <Button
                  onClick={() => {
                    if (this.state.isReadOnly == false) {
                      this.handleModalOpen()
                    }
                  }}
                >
                  Add
                </Button>
              }
              /* Modal props can go here */
              open={this.state.modalOpen}
            >
              <Modal.Content>
                <Form>
                  {elements.map(el =>
                    this.createQuestionsWithProps(
                      el.type,
                      el,
                      this.state.collectionElements
                    )
                  )}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() => this.handleModalClose('cancel')}
                >
                  Cancel
                </Button>
                <Button
                  positive
                  onClick={() => {
                    const checkElementValues = () => {
                      if ( this.state.newRow.length === this.state.collectionElements.length ) {
                        let returnValue = true;
                        for (let row of this.state.newRow) {
                          if (row.elementValue) {
                            continue;
                          } else {
                            returnValue = false;
                            break;
                          }
                        }
                        return returnValue;
                      } else {
                        return false;
                      }
                    }
                    if ( checkElementValues() ) {
                      this.handleModalClose(
                        'save',
                        'add',
                        handleCollectionSubmission
                      )
                    } else {
                      this.setState({ collectionErrorOpen: true });
                    }
                  }}
                >
                  Save
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={ this.state.collectionErrorOpen }
              onClose={ () => this.setState({ collectionErrorOpen: false }) }
              size="tiny"
            >
              <Modal.Header>
                Unable to save collection!
              </Modal.Header>
              <Modal.Content>
                All fields are required in order to save a new collection!
              </Modal.Content>
            </Modal>
            <Table
              className="dynamicTable"
              verticalAlign="middle"
              columns={
                this.state.labels !== undefined ? this.state.labels.length : 0
              }
              celled
              selectable
              striped
            >
              <Table.Header>
                <Table.Row textAlign="left" verticalAlign="middle">
                  <Table.HeaderCell>Modify</Table.HeaderCell>
                  <React.Fragment>
                    {this.state.labels !== undefined
                      ? this.state.labels.map(label => (
                          <React.Fragment>
                            <Table.HeaderCell>{label}</Table.HeaderCell>
                          </React.Fragment>
                        ))
                      : null}
                  </React.Fragment>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <React.Fragment>
                  {tableState !== undefined
                    ? tableState.map((row, index) => (
                        <React.Fragment>
                          <Table.Row textAlign="left" verticalAlign="middle">
                            <Modal
                              trigger={
                                <Table.Cell
                                  onClick={() =>
                                    this.changeTableState(
                                      tableState,
                                      this.state.collectionElements,
                                      row,
                                      index
                                    )
                                  }
                                >
                                  {this.state.isReadOnly == false ? (
                                    <React.Fragment>
                                      { console.log( row ) }
                                      <Button
                                        negative={
                                          this.state.initiateDelete !== index
                                            ? false
                                            : true
                                        }
                                        onClick={() => this.handleEditOpen(row)}
                                      >
                                        {this.state.initiateDelete !== index
                                          ? 'Edit'
                                          : 'Cancel'}
                                      </Button>
                                      <Button
                                        negative={
                                          this.state.initiateDelete !== index
                                            ? true
                                            : false
                                        }
                                        positive={
                                          this.state.initiateDelete === index
                                            ? true
                                            : false
                                        }
                                        onClick={() =>
                                          this.initiateDelete(
                                            removeCollectionElements,
                                            row,
                                            index
                                          )
                                        }
                                        style={{
                                          width: '20%',
                                          marginRight: '0.5em',
                                        }}
                                      >
                                        {this.state.initiateDelete !== index
                                          ? 'Delete'
                                          : this.state.confirmDelete !== index
                                          ? 'Confirm?'
                                          : null}
                                      </Button>
                                    </React.Fragment>
                                  ) : (
                                    <p>
                                      `You are not permitted to modify this
                                      record`
                                    </p>
                                  )}
                                </Table.Cell>
                              }
                              row={row}
                              index={index}
                              open={this.state.editOpen}
                            >
                              <Modal.Content>
                                <Form>
                                  {elements.map(el =>
                                    this.createQuestionsWithProps(
                                      el.type,
                                      el,
                                      this.state.collectionElements,
                                      row,
                                      'arg',
                                      index,
                                      this.numberInputRef
                                    )
                                  )}
                                </Form>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  negative
                                  onClick={() => this.handleEditClose('cancel')}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  positive
                                  onClick={() => {
                                    const checkElementValues = () => {
                                      if ( this.state.row.length === this.state.collectionElements.length ) {
                                        let returnValue = true;
                                        for (let row of this.state.row) {
                                          if (row.elementValue) {
                                            continue;
                                          } else {
                                            returnValue = false;
                                            break;
                                          }
                                        }
                                        return returnValue;
                                      } else {
                                        return false;
                                      }
                                    }
                                    if ( checkElementValues() ) {
                                      this.handleEditClose(
                                        'save',
                                        'edit',
                                        handleCollectionSubmission,
                                        row
                                      )
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                              </Modal.Actions>
                            </Modal>
                            {row.map(cell => (
                              <React.Fragment>
                                <Table.Cell style={{ width: '20%' }} >
                                  {cell.elementValue}
                                </Table.Cell>
                              </React.Fragment>
                            ))}
                          </Table.Row>
                        </React.Fragment>
                      ))
                    : console.log('loading')}
                </React.Fragment>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </BaseObjectConsumer>
    );
  }

  changeTableState = (tableData, properties, row, index) => {
    this.setState({ index: index, row: row });
  }

  createQuestionsWithProps = (
    type,
    elements,
    inputData,
    row,
    modalChange,
    index
  ) => {
    // const conditionElement = elements.options.map(cE => cE.conditionElement);
    // const conditionValues = elements.options.map(cV => cV.conditionValues);
    const { date } = this.state;

    switch (type) {
      case 4:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              control={Input}
              label={elements.label}
              value={this.findValue(
                inputData,
                this.props.elements,
                elements.id,
                row,
                index
              )}
              width={16}
              onChange={this.onChange}
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
              collection={this.props.collection}
            />
          </div>
        );
      case 5:
        let options = elements.options.map(o => ({
          key: o.id,
          text: o.label,
          value: o.id,
        }));

        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              row={row}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              label={elements.label}
              control={Dropdown}
              selection
              search
              value={this.findValue(
                inputData,
                this.props.elements,
                elements.id,
                row,
                index
              ) || 0}
              width={16}
              options={[ { key: 0, text: 'Select an option', value: 0 }, ...options ]}
              properties={inputData}
              onChange={this.onChange}
              // conditionElement={conditionalOptions}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 6:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              control={Radio}
              label={elements.label}
              value={this.findValue(
                inputData,
                this.props.elements,
                elements.id,
                row,
                index
              )}

              width={16}
              onChange={this.onChange}

              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 7:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Checkbox
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              // control={Checkbox}
              width={16}
              label={elements.label}
              value={this.state.isChecked == false ? elements.options[1].id : elements.options[0].id}

              width={16}
              onClick={() => this.setState({isChecked: !this.state.isChecked})}
              checked={this.state.isChecked}
              onChange={this.onChange}
              // indeterminate={this.state.isNeutral}
              // conditionElement={conditionElement}
              // conditionValues={conditionValues}
            />
          </div>
        );
      case 8:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              name={elements.label}
              control={Input}
              label={elements.label}
              defaultValue={this.state.numberRef}
              width={16}
              // seq={
              //   index !== undefined
              //     ? row[index] !== undefined
              //       ? row[index].seq
              //       : elements.length
              //     : Math.random() * 10
              // }
              ref={numberInputRef}
              onChange={this.handleChange}
              onClick={this.handleBlur}
            />
          </div>
        );
      case 14:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Input key={_.uniqueId()} label={elements.label}>
              <DatePicker
                onChange={value =>
                  this.onDateChange(value, elements.id, elements.seq)
                }
                selected={date}
              />
            </Form.Input>
          </div>
        );
      case 15:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Input
              key={_.uniqueId()}
              name="eventNote"
              id={elements.id}
              points={elements.points}
              width={16}
              style={{ minHeight: 200 }}
              control={TextArea}
              value={this.findValue(
                inputData,
                this.props.elements,
                elements.id,
                row,
                index
              )}
              // seq={
              //   index !== undefined
              //     ? row[index] !== undefined
              //       ? row[index].seq
              //       : elements.length
              //     : Math.random() * 10
              // }
              width={16}
              onChange={this.onChange}
              label={elements.label}
            />
          </div>
        );
      case 17:
        return (
          <div
            className={elements.points > 0 ? 'score-control' : null}
            style={{ display: elements.display, width: `${elements.width}%` }}
          >
            <Form.Field
              key={_.uniqueId()}
              id={elements.id}
              points={elements.points}
              value={this.findValue(
                inputData,
                this.props.elements,
                elements.id,
                row,
                index
              )}
              // seq={
              //   index !== undefined
              //     ? row[index] !== undefined
              //       ? row[index].seq
              //       : elements.length
              //     : Math.random() * 10
              // }
              width={16}
              onChange={this.onChange}
              style={{ minHeight: 200 }}
              control={Label}
              label={elements.label}
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { collectionElements, tableState } = this.state;
    const { collection, elements } = this.props;
    return collectionElements === undefined || collectionElements.length < 1 ? (
      <Segment style={{ height: '300px' }}>
        <Dimmer active size="massive">
          <Loader style={{ marginTop: '10px' }} />
        </Dimmer>
      </Segment>
    ) : tableState !== undefined ||
      this.props.inputData !== undefined ? (
      <BaseObjectConsumer>
        {({ baseObject, inputData, records, record, recordInput }) => (
          <Form>
            <Segment
              secondary
              raised
              style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <React.Fragment>
                {baseObject !== undefined && elements !== undefined ? (
                  <React.Fragment>
                    {this.createCollectionWithPropsTable(
                      tableState,
                      elements,
                      collectionElements
                    )}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </Segment>
          </Form>
        )}
      </BaseObjectConsumer>
    ) : (
      <Segment style={{ height: '300px' }}>
        <Dimmer active size="massive">
          <Loader
            style={{ marginTop: '10px' }}
            active={this.state.loading === true ? true : false}
          />
        </Dimmer>
      </Segment>
    );
  }
}
