import React from 'react';
import { Icon, List, Modal } from 'semantic-ui-react';

const PasswordConfirmError = ({ open, close }) => {
  return (
    <Modal
      open={ open }
      onClose={ close }
      size="mini"
    >
      <Modal.Header>
        Passwords must match!
      </Modal.Header>
      <Modal.Content>
        Please make sure the new password and confirm new password fields are matching.
      </Modal.Content>
    </Modal>
  );
}

export default PasswordConfirmError;
