import React from 'react';
import { Modal, Button, Icon, List } from 'semantic-ui-react';
import axios from 'axios';
import { url, key, getCookie } from '../../Util';
import Moment from 'react-moment';
import moment from 'moment';
import { NewSID } from '../Forms/NewSID';

import { SearchForm } from '../Forms/SearchForm';
import { Notes } from '../Forms/Notes';
import { NewCommunitySID } from '../Forms/NewCommunitySID';
import SyringesChart from './SyringesChart';

function createYearOptions() {
  let yearStart;
  let yearEnd;

  let date = new Date();
  let year = date.getFullYear();

  yearEnd = year - 12;
  yearStart = year - 90;

  let numberOfYears = yearEnd - yearStart + 1;
  let yearOptions = [];
  for (let i = 0; i < numberOfYears; i++) {
    if (yearStart++ <= yearEnd) {
      yearOptions.push({
        key: i,
        value: yearStart + 1,
        text: (yearStart + 1).toString(),
      });
    }
  }
  return yearOptions;
}

export class EventsModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      sid: {
        FIFN: '',
        FILN: '',
        MMFN: '',
        MMLN: '',
        MONTH: '',
        YEAR: '',
        GCODE: '',
      },
      smartID: '',
      findSmartIDstate: false,
      scramblerID: '',
      dateFrom: '',
      dateTo: '',
      confirmPost: false,
      searchError: false
    };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });

      let dateTo = JSON.parse( window.localStorage.getItem('DateTo') );
      let dateFrom = JSON.parse( window.localStorage.getItem('DateFrom') );
      if(dateTo && dateFrom) {
      dateTo = new Date(dateTo);
      dateFrom = new Date(dateFrom);
      this.setState({dateTo, dateFrom});
      }
    }

    let yearOptions = createYearOptions();
    this.setState({ yearOptions });
  }

  handleModal = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({
      open: false,
      sid: {
        FIFN: '',
        FILN: '',
        MMFN: '',
        MMLN: '',
        MONTH: '',
        YEAR: '',
        GCODE: '',
      },
      findSmartIDstate: false,
      smartID: '',
      confirmPost: false
    });
  }

  toggleConfirmPost = (value, smartID) => {
    this.setState({ confirmPost: value, smartID });
  }

  handleSubmit = sid => {
    if (
      this.props.buttonText === 'New Participant' &&
      this.state.confirmPost == true &&
      this.state.smartID.length >= 11
    ) {
      this.props.postNewParticipant(this.state.smartID, 'Participant');
      this.handleClose();
    }

    if (this.props.buttonText == 'New Community' && this.state.confirmPost == true && this.state.smartID.length >= 7) {
      this.props.postNewParticipant(this.state.smartID, 'Community');
      this.handleClose();
    }

    if (this.props.buttonText === 'Reminders') {
      this.handleClose();
    }

    if (this.props.buttonText === 'Search') {
      const dateFrom = moment(this.state.dateFrom);
      const dateTo = moment(this.state.dateTo);
      if ( this.state.dateTo && this.state.dateFrom && ( this.state.dateTo > this.state.dateFrom ) ) {
        this.props.handleSearch(
          this.state.scramblerID,
          this.state.smartID,
          dateFrom,
          dateTo
        );
        this.handleClose();
      } else {
        this.setState({ searchError: true });
      }
    }
    if (
      this.props.smartID !== this.state.smartID &&
      this.state.smartID.length >= 11 &&
      this.state.confirmPost !== false && this.props.buttonText !== 'New Participant' && this.props.buttonText !== 'New Community') {
      this.props.updateSmartID(this.state.smartID);
      this.handleClose();
    }

    if(this.props.smartID !== this.state.smartID && this.state.smartID.length >= 7 && this.state.smartID.length < 11 && this.state.confirmPost !== false && this.props.buttonText !== 'New Participant' && this.props.buttonText !== 'New Community') {
      this.props.updateSmartID(this.state.smartID);
      this.handleClose();
    }
  }

  updateSIDValue = (event, { name, value }) => {
    const sid = { ...this.state.sid, [name]: value};
    this.setState({ sid });
  }

  createSID = () => {
    let concatSID = '';
    let { sid } = this.state;
    for (var key in sid) {
      concatSID = concatSID.concat(sid[key]).toUpperCase();
    }
    this.setState({
      smartID: concatSID,
      findSmartIDstate: !this.state.findSmartIDstate,
      sid: {
        FIFN: '',
        FILN: '',
        MMFN: '',
        MMLN: '',
        MONTH: '',
        YEAR: '',
        GCODE: '',
      }
    });
  }

  onChange = (event, { name, value }) => {
    this.setState({ [name]: value});
  }

  findSmartID = () => {
    this.setState({ findSmartIDstate: !this.state.findSmartIDstate });
  }

  cancelFindSmartID = () => {
    this.setState({
      findSmartIDstate: false,
      sid: {
        FIFN: '',
        FILN: '',
        MMFN: '',
        MMLN: '',
        MONTH: '',
        YEAR: '',
        GCODE: '',
      }
    })
  }

  onFromChange = value => {
    const dateFrom = value;
    const DateFrom = moment(dateFrom).format('MM/DD/YYYY');
    window.localStorage.setItem('DateFrom', dateFrom);
    // let dateTo;
    // let DateTo;
    // let storedDateTo = window.localStorage.getItem('DateTo');
    // if(storedDateTo !== null && this.state.DateTo == undefined) {
    //   dateTo = moment(storedDateTo);
    //   DateTo = dateTo;
    //   this.setState({ dateTo, DateTo });
    // }
    this.setState({ DateFrom, dateFrom});
  }

  onToChange = value => {
    const dateTo = value;
    const DateTo = moment(dateTo).format('MM/DD/YYYY');
    window.localStorage.setItem('DateTo', dateTo);
    // let dateFrom;
    // let DateFrom;
    // let storedDateFrom = window.localStorage.getItem('DateFrom');
    // if(storedDateFrom !== null && this.state.DateFrom == undefined) {
    //   dateFrom = moment(storedDateFrom);
    //   DateFrom = dateFrom;
    //   this.setState({DateFrom, dateFrom});
    // }
    this.setState({ DateTo, dateTo });
  }


  render() {
    return (
      <Modal
      style={{ minWidth: '900px' }}
        closeIcon={<Icon name="window close" color="violet" />}
        trigger={
          <Button
            className={this.props.className}
            content={this.props.iconButton ? '' : this.props.buttonText}
            icon={this.props.icon}
            label={this.props.label}
            onClick={this.handleModal}
          />
        }
        open={this.state.open}
        onClose={this.handleClose}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        size="large"
      >
        <Modal.Content>
          {this.props.buttonText === 'New Participant' ? (
            <NewSID
              // postNewParticipant={this.props.postNewParticipant}
              toggleConfirmPost={this.toggleConfirmPost}
            />
          ) : this.props.buttonText === 'Search' ? (
            <SearchForm
              handleSearch={this.handleSearch}
              {...this.state}
              onChange={this.onChange}
              onFromChange={this.onFromChange}
              onToChange={this.onToChange}
              findSmartID={this.findSmartID}
              cancelFindSmartID={this.cancelFindSmartID}
              updateSIDValue={this.updateSIDValue}
              createSID={this.createSID}
              yearOptions={this.state.yearOptions}
              recordType={this.props.recordType}
              toggleConfirmPost={this.toggleConfirmPost}
              DateFrom={this.state.DateFrom}
              DateTo={this.state.DateTo}
              storedDateFrom={window.localStorage.getItem('DateFrom')}
              storedDateTo={window.localStorage.getItem('DateTo')}
            />
          ) : this.props.buttonText === 'Chart' ? (
            <SyringesChart participantID={ this.props.participantID } />
          ) : this.props.buttonText === 'Reminders' ? (
            <Notes
              notes={this.props.notes}
              addUpdateBaseNotes={this.props.addUpdateBaseNotes}
            />
          ) : this.props.smartID !== undefined && this.props.baseType == 18 ? (
            <NewSID
              smartID={this.props.smartID}
              toggleConfirmPost={this.toggleConfirmPost}
            />
          ) : this.props.smartID !== undefined && this.props.baseType == 19 ? (
            <NewCommunitySID
              smartID={this.props.smartID}
              toggleConfirmPost={this.toggleConfirmPost}
              lookup={false}
            />
          ) : this.props.buttonText === 'New Community' ? (
            <NewCommunitySID
              // postNewParticipant={this.props.postNewParticipant}
              toggleConfirmPost={this.toggleConfirmPost}
              lookup={false}
            />
          ) : null}
        </Modal.Content>
        { !this.props.hideActions
          ? <Modal.Actions>
              <Button color="red" onClick={this.handleClose}>
                <Icon name="close" /> Cancel
              </Button>
              <Button color="green" onClick={this.handleSubmit}>
                <Icon name="checkmark" /> Confirm
              </Button>
            </Modal.Actions>
          : null
        }
        <Modal
          size="tiny"
          open={ this.state.searchError }
          onClose={ () => this.setState({ searchError: false })}
        >
          <Modal.Content>
            Invalid Dates! Please select a Start Date and End Date to search. End Date must be at least one day greater than Start Date.
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={ () => this.setState({ searchError: false })}
            >
              close
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal>
    );
  }
}
