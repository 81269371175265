import React from 'react';
import axios from 'axios';
import { key, url, getCookie } from '../Util';
import _ from 'lodash';

const AIContext = React.createContext();

export class AIContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { baseObject: {}, aiTaskResponse: {} };
  }

  componentDidMount() {
    if (this.state.token === undefined) {
      let token = getCookie('token');
      this.setState({ token });
    }

    if (this.props.values !== undefined && this.props.values.elements !== undefined) {
      this.setState({ elements: this.props.values.elements });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { values } = this.props;

    if (
      prevProps.values !== undefined &&
      prevProps.values.activeIndex !== values.activeIndex
    ) {
      this.runAITask();
    }

    if (
      prevProps.values !== undefined &&
      prevProps.values.baseObject !== values.baseObject
    ) {
      let baseID = values.baseObject.id;
      let baseObject = values.baseObject;

      this.setState({ baseID, baseObject });
    }
  }

  runAITask = () => {
    // console.log(this.props.values.aiTasks, this.state.baseObject, this.state.elements);
    let aiTasks = this.props.values.aiTasks;
    this.state.elements.map(element => {
      let includes = _.includes(aiTasks, element.id);
      let baseObject = this.state.baseObject;
      if (includes == true) {
        let id = element.id;
        axios({
          method: 'post',
          url: url + 'AI/' + id,
          headers: {
            api_key: key,
            AccessToken: getCookie('token'),
          },
          data: baseObject,
        }).then(res => {
          let aiTaskResponse = res.data;
          this.setState({ aiTaskResponse });
        });
      }
    });
  }

  render() {
    const { children } = this.props;
    return (
      <AIContext.Provider
        value={{
          aiTaskResponse: this.state.aiTaskResponse,
        }}
      >
        {children}
      </AIContext.Provider>
    );
  }
}

export const AIContextConsumer = AIContext.Consumer;
