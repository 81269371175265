import React from 'react';
import { Tab } from 'semantic-ui-react';
import { Dashboard } from '../Dashboard/Dashboard';
import { BaseObjectProvider, BaseObjectConsumer } from '../../Context/BaseObjectContext';

export class EventsSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    if(this.props.panes !== undefined) {
      this.setState({panes: this.props.panes});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.activeIndex !== this.state.activeIndex) {
      this.setState({panes: this.props.panes})
    }
  }


  handleChange = (event, data) => {
    this.props.setActiveIndex(data.activeIndex);
    this.setState({activeIndex: data.activeIndex});
  }

  render() {
    return (
      <BaseObjectConsumer>
      {({baseObject, inputData}) =>
        <Tab
          className='mainFormWrappper'
          activeIndex={this.props.activeIndex}
          setActiveIndex={this.props.setActiveIndex}
          menu={{
            className: 'eventSideBar',
            fluid: true,
            vertical: true,
            tabular: false,
            compact: true,
          }}
          grid={{ paneWidth: this.props.width > 992 ? 13 : 13, tabWidth: this.props.width > 992 ? 2 : 3 }}
          panes={this.props.panes}
          renderActiveOnly={true}
          activeComponent={this.props.activeComponent}
          setActiveComponent={this.props.setActiveComponent}
          interfaces={this.props.interfaces}
          onTabChange={this.handleChange}
          inputData={inputData}
          baseObject={baseObject}
          style={this.props.width < 992 ? {marginTop: '-4em'} : null}
          profile={this.props.profile}
        />
        }
      </BaseObjectConsumer>
    );
  }
}
