import React from 'react';
import {
  Form,
  Header,
  Button,
  Icon,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { NewCommunitySID } from './NewCommunitySID';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';

const genderOptions = [
  { key: 0, value: 1, text: 'Male' },
  { key: 1, value: 2, text: 'Female' },
  { key: 2, value: 3, text: 'Transgender/Other/Unknown' },
];

const dateOptions = [
  { key: 0, value: '01', text: '01 - January' },
  { key: 1, value: '02', text: '02 - February' },
  { key: 2, value: '03', text: '03 - March' },
  { key: 3, value: '04', text: '04 - April' },
  { key: 4, value: '05', text: '05 - May' },
  { key: 5, value: '06', text: '06 - June' },
  { key: 6, value: '07', text: '07 - July' },
  { key: 7, value: '08', text: '08 - August' },
  { key: 8, value: '09', text: '09 - September' },
  { key: 9, value: '10', text: '10 - October' },
  { key: 10, value: '11', text: '11 - November' },
  { key: 11, value: '12', text: '12 - December' },
];

export class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static contextType = BaseObjectConsumer;


  render() {
    const {
      dateFrom,
      dateTo,
      sid,
      smartID,
      scramblerID,
      findSmartIDstate,
      yearOptions,
    } = this.props;
    return findSmartIDstate === false ? (
      <Form>
        <Header dividing>
          <Icon name="search" />
          Search For {this.props.recordType}
        </Header>
        <Form.Group widths="equal">
          <Form.Input
            name="scramblerID"
            label="Scrambler ID"
            value={scramblerID ? scramblerID.toUpperCase() : this.context.baseObject !== undefined ? this.context.baseObject.scramblerID : null}
            placeholder='ABC02DEF98EH'
            onChange={this.props.onChange}
          />
          <Form.Input
            name="smartID"
            label={this.props.recordType !== 'Community' ? 'Smart ID' : 'Community ID'}
            value={smartID ? smartID.toUpperCase() : this.context.baseObject !== undefined ? this.context.baseObject.smartID : null}
            placeholder={this.props.recordType == 'Participant' ? 'ABCD0020190' : '00AGCY00'}
            onChange={this.props.onChange}
          />
          <Button
            className="btnSmartLookup"
            basic
            onClick={this.props.findSmartID}
            icon
          >
            <Icon name="wpforms" size="big" />
          </Button>
          <Form.Input label="Start Date" disabled={ scramblerID || smartID }>

            <DatePicker
              selected={dateFrom}
              onChange={this.props.onFromChange}
              placeholderText='MM/DD/YYYY'
              value={this.props.dateFrom}
              isClearable
              customInput={
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  keepCharPositions= {true}
                  guide = {true}
                />
              }
            />
          </Form.Input>
          <Form.Input label="End Date" disabled={ scramblerID || smartID }>
            <DatePicker
              onChange={this.props.onToChange}
              selected={dateTo}
              placeholderText={'MM/DD/YYYY'}
              value={this.props.dateTo}
              isClearable
              customInput={
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  keepCharPositions= {true}
                  guide = {true}
                />
              }
            />
          </Form.Input>
        </Form.Group>
      </Form>
    ) : (
      <Form>
       <Header dividing>
         <Icon name="search" />
          Search For {this.props.recordType}
       </Header>
      {this.props.recordType !== 'Community' ? (
        <React.Fragment>
        <Form.Group widths="equal">
          <Form.Input
            name="FIFN"
            label="First Initial (First Name)"
            value={sid.FIFN.toUpperCase()}
            onChange={this.props.updateSIDValue}
            maxLength="1"
            autoComplete="off"
          />
          <Form.Input
            name="FILN"
            label="First Initial (Last Name)"
            value={sid.FILN.toUpperCase()}
            onChange={this.props.updateSIDValue}
            maxLength="1"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="MMFN"
            label="Mothers Maiden Name First Initial (First Name)"
            value={sid.MMFN.toUpperCase()}
            onChange={this.props.updateSIDValue}
            maxLength="1"
            autoComplete="off"
          />
          <label />
          <Form.Input
            name="MMLN"
            label="Mothers Maiden Name First Initial (Last Name)"
            value={sid.MMLN.toUpperCase()}
            onChange={this.props.updateSIDValue}
            maxLength="1"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Dropdown
            name="MONTH"
            selection
            options={dateOptions}
            label="Birth Month"
            value={sid.MONTH}
            onChange={this.props.updateSIDValue}
          />
          <Form.Dropdown
            name="YEAR"
            selection
            options={yearOptions}
            label="Birth Year"
            value={sid.YEAR}
            onChange={this.props.updateSIDValue}
          />
          <Form.Dropdown
            name="GCODE"
            selection
            options={genderOptions}
            label="Gender"
            value={sid.GCODE}
            onChange={this.props.updateSIDValue}
          />
        </Form.Group>
        </React.Fragment>
      ) : (
        <NewCommunitySID
          toggleConfirmPost={this.props.toggleConfirmPost}
          lookup={true}
        />
      )}
        <Button
          onClick={this.props.createSID}
          positive
        >
          Retrieve {this.props.recordType} ID
        </Button>
        <Button
          onClick={this.props.cancelFindSmartID}
          negative
          style={{ marginLeft: '15px' }}
        >
          Cancel
        </Button>
      </Form>
    );
  }
}
