import React from 'react';
import { Progress } from 'semantic-ui-react';
import { BaseObjectConsumer } from '../../Context/BaseObjectContext';
import { PointsContextConsumer } from '../../Context/PointsContext';

export class ProgressBar extends React.Component {


  render() {

    return (
      <PointsContextConsumer>
      {({points, totalPoints}) =>
      <Progress
        value={points}
        total={totalPoints}
        progress="percent"
        percent={Math.round( (points/totalPoints)*100 )}
        color={this.props.color}
      />
      }
      </PointsContextConsumer>

    );
  }
}
