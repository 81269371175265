import React, { Component } from 'react';
import { Main } from './Containers/Main';
import { Login } from './Containers/Login';
import { Route } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import { key, url, setCookie } from './Util';
import { Header, Dimmer, Loader, Modal } from 'semantic-ui-react';
import { ReferralContainer } from './Components/ReferralCapture/ReferralContainer';
import { BaseObjectProvider } from './Context/BaseObjectContext';
import browser from 'browser-detect';


// import { FormikForm } from './Components/Forms/FormikForm';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      isLoading: false,
      error: false,
      referralSuccess: null,
      isAcceptedBrowser: null,
      appLoading: true,
      appError: false
    };
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  componentDidMount() {
    if ( this.detectMob() ) {
      this.setState({ isAcceptedBrowser: false });
    }
    this.getAppInfo();
    const browserInfo = browser();
    const { name, versionNumber } = browserInfo;
    switch ( name ) {
      case 'chrome':
        if ( versionNumber >= 74 ) {
          this.setState({ isAcceptedBrowser: true });
          break;
        } else {
          this.setState({ isAcceptedBrowser: false });
          break;
        }
      case 'safari':
        if ( versionNumber >= 11 ) {
          this.setState({ isAcceptedBrowser: true });
          break;
        } else {
          this.setState({ isAcceptedBrowser: false });
          break;
        }
      case 'firefox':
        if ( versionNumber >= 66 ) {
          this.setState({ isAcceptedBrowser: true });
          break;
        } else {
          this.setState({ isAcceptedBrowser: false });
          break;
        }
      case 'edge':
        if ( versionNumber >= 18 ) {
          this.setState({ isAcceptedBrowser: true });
          break;
        } else {
          this.setState({ isAcceptedBrowser: false });
          break;
        }
      case 'IE':
        if ( versionNumber >= 11 ) {
          this.setState({ isAcceptedBrowser: true });
          break;
        } else {
          this.setState({ isAcceptedBrowser: false });
          break;
        }
      default:
        this.setState({ isAcceptedBrowser: false });
        break;
    }
  }

  getAppInfo = async () => {
    try {
      const { data: { app_Version } } = await axios({
        method: 'get',
        baseURL: url,
        url: '/info',
        headers: {
          api_key: key,
          Accept: 'application/json',
        },
      });
      const currentVersion = localStorage.getItem( 'appVersion' );
      if ( !currentVersion || ( JSON.parse( currentVersion ) !== app_Version ) ) {
        localStorage.setItem( 'appVersion', JSON.stringify( app_Version ) );
        window.location.reload(true);
      }
      this.setState({ appLoading: false });
    } catch (e) {
      this.setState({ apiError: true });
    }
  }

  auth = ( username, password ) => {
    this.setState({ isLoading: true });
    if (this.state.loggedIn === false) {
      password = btoa(password);
      axios({
        method: 'get',
        url:
          url +
          'user/authenticate?UserName=' +
          username +
          '&Password=' +
          password,
        headers: {
          api_key: key,
        },
      })
        .then(res => {
          setCookie('token', res.data.token, 7);
          this.setState({
            token: res.data.token,
            valid: res.data.valid,
            loggedIn: true,
            userName: username,
            userID: res.data.userID,
            isLoading: false,
          });
        })
        .catch(res => this.setState({ error: true, isLoading: false }));
    } else if (this.state.valid === true) {
      // eslint-disable-next-line no-undef
      setCookie('token', res.data.token, 7);
      this.setState({
        // eslint-disable-next-line no-undef
        token: res.data.token,
        loggedIn: true,
        userName: username,
        valid: true,
        // eslint-disable-next-line no-undef
        userID: res.data.userID,
        isLoading: false,
      });
      this.props.history.push('/dashboard');
    }
  }

  twoFactor = code => {
    this.setState({ isLoading: true });
    if (
      (this.state.loggedIn === true && this.state.valid === false) ||
      this.state.token === undefined ||
      this.state.token === null
    ) {
      const username = this.state.userName;
      axios({
        method: 'get',
        url:
          url +
          'user/phase2authenticate?UserName=' +
          username +
          '&Code=' +
          code,
        headers: {
          api_key: key,
          AccessToken: this.state.token,
        },
      })
        .then(res => {
          setCookie('token', res.data.token);
          this.setState({
            token: res.data.token,
            userID: res.data.userID,
            valid: res.data.valid,
            isLoading: false,
          });
        })
        .catch(res => this.setState({ error: true, isLoading: false }));
    } else {
      this.props.history.push('/dashboard');
    }
  }

  resetError = () => {
    this.setState({ error: false });
  }

  setError = () => {
    this.setState({error: true});
  }

  submitReferral = (login, pin, refcode) => {
    let refCode = refcode;

    axios({
      method: 'post',
      url: `${url}Provider/referral?Login=${login}&Pin=${pin}&RefCode=${refCode}`,
      headers: {
        api_key: key,
        AccessToken: this.state.token,
      },
    }).then(res => {

      this.setState({ referralSuccess: res.data, refCode });
    });
  }

  render() {
    const { isAcceptedBrowser } = this.state;

    if ( isAcceptedBrowser === null ) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal
          open={ this.state.apiError }
          size="mini"
        >
          <Modal.Content style={{ textAlign: 'center' }}>
            <img
              className="loginLogo"
              src={ `${ process.env.PUBLIC_URL }/assets/images/logo.png` }
              alt="Indiana State Seal"
              style={{ width: '150px' }}
            />
            <Header
              dividing
              style={{ marginBottom: '5px', fontWeight: '300', fontSize: '20px' }}
              size="large"
            >
              Indiana State Department of Health
            </Header>
            <Header
              style={{ marginTop: '0px', fontWeight: '300' }}
            >
              Syringe Service Program
            </Header>
            <br />
            <br />
            <p>The app is currently down for maintenance!</p>
          </Modal.Content>
        </Modal>
        { !this.state.appLoading
            ? <React.Fragment>
                { isAcceptedBrowser
                  ? <div className="App">
                      <Route
                        path="/login"
                        render={props => (
                          <Login
                            {...this.state}
                            {...props}
                            auth={this.auth}
                            twoFactor={this.twoFactor}
                            error={this.state.error}
                            resetError={this.resetError}
                            setError={this.setError}
                          />
                        )}
                      />
                      <Route
                        path="/reset"
                        render={props => (
                          <Login
                            {...this.state}
                            {...props}
                            error={this.state.error}
                            setError={this.setError}
                            auth={this.auth}
                            resetError={this.resetError}
                            twoFactor={this.twoFactor}
                            userID={this.state.userID}
                          />
                        )}
                      />
                      <React.Fragment>
                        <Route
                          path="/dashboard"
                          render={props => (
                            <BaseObjectProvider>
                              <Main {...this.state} {...props}  />
                            </BaseObjectProvider>
                          )}
                          />
                        <Route
                          path="/referral"
                          render={props => (
                            <ReferralContainer
                              {...this.state}
                              {...props}
                              submitReferral={this.submitReferral}
                            />
                          )}
                        />
                      </React.Fragment>
                    </div>
                  : <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        margin: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ padding: '20px', backgroundColor: '#fff', boxShadow: '1px 1px 10px rgba(0,0,0,0.2)' }}>
                        <h2>You are using an unsupported browser!</h2>
                        <hr />
                        <br />
                        <p> The following browsers are supported:</p>
                        <ul>
                          <li>Chrome - version 74 and up</li>
                          <li>Firefox - version 66 and up</li>
                          <li>Safari - version 11 and up</li>
                          <li>Edge - version 18 and up</li>
                          <li>Internet Explorer - version 11 and up</li>
                        </ul>
                      </div>
                    </div>
                }
              </React.Fragment>
            : <Dimmer active>
                <Loader
                  style={{ width: '30vw', height: '30vh' }}
                  size="massive"
                />
              </Dimmer>
        }
      </React.Fragment>
    );
  }
}

export default App;
