import React from 'react';
import { Form, Header, Button, Icon, TextArea } from 'semantic-ui-react';

export class NotificationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const newNotification = (
      <NewNotification
        notification={this.props.notification}
        handleNotificationChange={this.props.handleNewNotification}
        getNotifications={this.props.getNotifications}
      />
    );

    const editNotification = (
      <EditNotification
        notification={this.props.notification}
        handleNotificationChange={this.props.handleNotificationChange}
        getNotifications={this.props.getNotifications}
      />
    );
    return (
      <React.Fragment>
        {this.props.render({
          newNotification: newNotification,
          editNotification: editNotification,
        })}
      </React.Fragment>
    );
  }
}

const NewNotification = props => {
  return (
    <React.Fragment>
      <Header>
        <Header.Content>
          Notification ID: {props.notification.id}
          <Header sub>
            Notification Status:
            {props.notification.notificationStatus === 12 ? (
              <p
                style={{
                  color: 'green',
                  fontWeight: 'bold',
                  display: 'inline',
                  paddingLeft: '2px',
                }}
              >
                {' '}
                Active{' '}
              </p>
            ) : (
              <p
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  display: 'inline',
                  paddingLeft: '2px',
                }}
              >
                {' '}
                Disabled{' '}
              </p>
            )}
          </Header>
        </Header.Content>
      </Header>
      <Form.TextArea
        name="content"
        label="Notification Content"
        style={{ height: '15em' }}
        control={TextArea}
        width={14}
        value={props.notification.content}
        onChange={props.handleNotificationChange}
      />
    </React.Fragment>
  );
};

const EditNotification = props => {
  return (
    <React.Fragment>
      <Header>
        <Header.Content>
          Notification ID: {props.notification.id}
          <Header sub>
            Notification Status:
            {props.notification.notificationStatus === 12 ? (
              <p
                style={{
                  color: 'green',
                  fontWeight: 'bold',
                  display: 'inline',
                  paddingLeft: '2px',
                }}
              >
                {' '}
                Active{' '}
              </p>
            ) : (
              <p
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  display: 'inline',
                  paddingLeft: '2px',
                }}
              >
                {' '}
                Disabled{' '}
              </p>
            )}
          </Header>
        </Header.Content>
      </Header>
      <Form.TextArea
        name="content"
        label="Notification Content"
        style={{ height: '15em' }}
        control={TextArea}
        width={14}
        value={props.notification.content}
        onChange={props.handleNotificationChange}
      />
      <Form.Checkbox
        label="Disable Notification"
        name="notificationStatus"
        width={12}
        toggle
        checked={props.notification.notificationStatus === 20}
        value={props.notification.notificationStatus}
        onClick={props.handleNotificationChange}
      />
    </React.Fragment>
  );
};
